import React, { useRef } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer,
} from 'recharts';
import useMonthlySpending from '../hooks/useMonthlySpending';
import palette from '../utils/palette';
import useLayoutWidth from '../hooks/useLayoutWidth';

const MonthlySpendingChart = () => {
  const monthlySpending = useMonthlySpending();
  const divRef = useRef();
  const width = useLayoutWidth(divRef);
  return (
    <div ref={divRef}>
      <ResponsiveContainer width="100%" height={width * 0.5}>

        <BarChart
          data={monthlySpending}
        >
          <XAxis dataKey="name" />
          <YAxis
            width={40}
          />
          <Tooltip />
          <Bar dataKey="buy" fill={palette.upGreenText} />
          <Bar dataKey="sell" fill={palette.downRedText} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MonthlySpendingChart;
