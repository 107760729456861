import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

const useMonthlySpending = (year = moment().year()) => {
  const orders = useSelector((state) => state.user.orders);
  const [monthlySpending, setMonthlySpending] = useState([]);
  useEffect(() => {
    const monthlySpendingObject = {};
    const filteredOrdersByYear = orders.filter((order) => moment(order.date).format('YYYY') === year.toString());
    filteredOrdersByYear.forEach((order) => {
      const month = moment(order.date).month();
      if (!monthlySpendingObject[month]) {
        monthlySpendingObject[month] = {
          buy: 0,
          sell: 0,
        };
      }
      monthlySpendingObject[month][order.action] += order.shares * order.price;
    });
    setMonthlySpending(Object.keys(monthlySpendingObject).sort().map((m) => ({
      name: moment(parseInt(m, 10) + 1, 'M').format('MMM'),
      buy: monthlySpendingObject[m].buy.toFixed(2),
      sell: monthlySpendingObject[m].sell.toFixed(2),
    })));
  }, [orders, year]);

  return monthlySpending;
};

export default useMonthlySpending;
