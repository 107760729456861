import moment from 'moment';
import Stock from './stock';
import { db } from '../apis/firebase';
import Order from './order';

/** @typedef {import('./typedef').Order} Order */
/** @typedef {import('./typedef').UserStockReturn} UserStockReturn */

const TIMERANGE = {
  TODAY: 0,
  DAYS5: 1,
  MONTH1: 2,
  MONTHS3: 3,
  MONTHS6: 4,
  YEAR1: 5,
  TOTAL: 6,
};

class UserStock extends Stock {
  constructor(symbol, uid, averagePrice, shares) {
    super(symbol);
    this.user = {
      id: uid,
      averagePrice,
      shares,
      cost: averagePrice * shares,
      orders: [],
      todayReturn: {
        meta: {
          cost: 0,
          equity: 0,
        },
        equity: 0,
        percentage: 0,
      },
      fiveDaysReturn: {
        meta: {
          cost: 0,
          equity: 0,
        },
        equity: 0,
        percentage: 0,
      },
      oneMonthReturn: {
        meta: {
          cost: 0,
          equity: 0,
        },
        equity: 0,
        percentage: 0,
      },
      threeMonthsReturn: {
        meta: {
          cost: 0,
          equity: 0,
        },
        equity: 0,
        percentage: 0,
      },
      totalReturn: {
        meta: {
          cost: 0,
          equity: 0,
        },
        equity: 0,
        percentage: 0,
      },
    };
  }

  /**
   *
   * @param {Number} timeRange
   * @return {Number} Then price
   */
  getPriceWithTimeRange(timeRange) {
    switch (timeRange) {
      case TIMERANGE.TODAY:
        return this.quote.latestPrice - this.todayReturn.price;
      case TIMERANGE.DAYS5:
        return this.quote.latestPrice - this.fiveDaysReturn.price;
      case TIMERANGE.MONTH1:
        return this.quote.latestPrice - this.oneMonthReturn.price;
      case TIMERANGE.MONTHS3:
        return this.quote.latestPrice - this.threeMonthsReturn.price;
      case TIMERANGE.TOTAL: {
        return 0;
      }
      default:
        throw new Error('Invalid time range.');
    }
  }

  /**
   *
   * @param {Number} timeRange
   * @return {Date} Target date
   */
  getDateWithTimeRange(timeRange) {
    switch (timeRange) {
      case TIMERANGE.TODAY:
        return moment().subtract(1, 'days').toDate();
      case TIMERANGE.DAYS5:
        return moment().subtract(5, 'days').toDate();
      case TIMERANGE.MONTH1:
        return moment().subtract(1, 'months').toDate();
      case TIMERANGE.MONTHS3:
        return moment().subtract(3, 'months').toDate();
      case TIMERANGE.TOTAL: {
        const sortedOrders = this.user.orders.slice().sort((a, b) => a.date - b.date);
        const earliestDate = sortedOrders[0].date;
        return new Date(earliestDate);
      }
      default:
        throw new Error('Invalid time range.');
    }
  }

  /**
   *
   * @param {Number} timeRange
   * @return {Number} Cost
   */
  getCostWithTimeRange(timeRange) {
    const targetDate = this.getDateWithTimeRange(timeRange);
    const thenPrice = this.getPriceWithTimeRange(timeRange);
    const shareHeldBefore = this.user.orders
      .filter((order) => order.date < targetDate)
      .reduce((shares, order) => (shares + order.shares * (order.action === 'buy' ? 1 : -1)), 0);
    const buyOrders = this.user.orders.filter((order) => order.action === 'buy');
    const costAfter = buyOrders
      .filter((order) => order.date >= targetDate)
      .reduce((total, order) => total + order.shares * order.price, 0);
    return thenPrice * shareHeldBefore + costAfter;
  }

  /**
   *
   * @param {Number} timeRange
   * @return {Number} Equity including cash
   */
  getEquityIncludingCashWithTimeRange(timeRange) {
    const targetDate = this.getDateWithTimeRange(timeRange);
    const sellOrders = this.user.orders.filter((order) => order.action === 'sell');
    const cashHeldAfter = sellOrders
      .filter((order) => order.date >= targetDate)
      .reduce((total, order) => total + order.shares * order.price, 0);
    const currentEquity = this.quote.latestPrice * this.user.shares;
    return currentEquity + cashHeldAfter;
  }

  /**
   * @param {Number} timeRange
   * @return {UserStockReturn}
   */
  calculateReturnWithTimeRange(timeRange) {
    // Cost:
    // Number of shares held before given time * then price
    // +
    // Sum of cost (shares * price) for each order after given time

    // Equity:
    // Cash held (sell) after given time
    // +
    // Current equity
    const cost = this.getCostWithTimeRange(timeRange);
    const totalEquity = this.getEquityIncludingCashWithTimeRange(timeRange);
    const equityReturn = totalEquity - cost;
    const percentageReturn = (equityReturn / cost) * 100;
    return {
      meta: {
        cost,
        equity: totalEquity,
      },
      equity: equityReturn,
      percentage: percentageReturn,
    };
  }

  calculateDiversityAndReturn(totalEquity) {
    if (!this.quote) {
      throw Object({
        message: 'Quote unknown yet.',
      });
    }
    const equity = this.user.shares * this.quote.latestPrice;
    this.user.equity = equity;
    this.user.diversity = (equity / totalEquity) * 100;
  }

  /**
   * @returns {Array<Order>}
   */
  async getOrders() {
    const orderQuerySnapshot = await db
      .collection('users').doc(this.user.id)
      .collection('stocks').doc(this.symbol)
      .collection('orders')
      .get();
    this.user.orders = orderQuerySnapshot.docs
      .map((order) => new Order(order.data(), order.id, this.uid).toJson());
    this.user.todayReturn = this.calculateReturnWithTimeRange(TIMERANGE.TODAY);
    this.user.fiveDaysReturn = this.calculateReturnWithTimeRange(TIMERANGE.DAYS5);
    this.user.oneMonthReturn = this.calculateReturnWithTimeRange(TIMERANGE.MONTH1);
    this.user.threeMonthsReturn = this.calculateReturnWithTimeRange(TIMERANGE.MONTHS3);
    this.user.totalReturn = this.calculateReturnWithTimeRange(TIMERANGE.TOTAL);
  }

  toJson() {
    return {
      ...super.toJson(),
      user: this.user,
    };
  }
}

export default UserStock;
