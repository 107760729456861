import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

const styles = {
  button: {
    borderRadius: 999,
    marginRight: 10,
    marginBottom: 10,
  },
};

const FilterTag = ({ selected, children, onClick }) => {
  const handleClicked = (e) => {
    e.preventDefault();
  };
  return (
    <Button
      onClick={onClick}
      onMouseDown={handleClicked}
      variant={selected ? 'third' : 'forth'}
      style={styles.button}
      size="sm"
    >
      {children}
    </Button>
  );
};

FilterTag.defaultProps = {
  selected: false,
};

FilterTag.propTypes = {
  selected: PropTypes.bool,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FilterTag;
