import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import HomePage from './HomePage';
import HistoryPage from './HistoryPage';
import StocksPage from './StocksPage';
import NewsPage from './NewsPage';
import SpinningPage from './SpinningPage';
import SingleStockPage from './SingleStockPage';
import EditOrderModal from './EditOrderModal';
import SettingPage from './SettingsPage';
import UnauthorizedPage from './UnauthorizedPage';
import ReturnPage from './ReturnPage';
import useFetchPortFolio from '../hooks/useFetchPortfolio';
import useScreenSize from '../hooks/useScreenSize';
import MobileNavbar from './MobileNavbar';
import MobileHomePage from './MobileHomePage';
import palette from '../utils/palette';
import padding from '../utils/padding';

const styles = {
  container: {
    marginLeft: 260,
    marginTop: 70,
    backgroundColor: palette.backgroundGray,
    minHeight: '100vh',
  },
  innerContainer: {
    padding: padding.mainApp,
  },
};

const MainApp = () => {
  const { loading, errMsg } = useFetchPortFolio();
  const { isMobile } = useScreenSize();
  return loading ? (
    <SpinningPage />
  ) : (
    <>
      {isMobile ? <MobileNavbar /> : <Navbar />}
      {!errMsg && !isMobile && <Sidebar />}
      <div style={{ ...styles.container, ...isMobile && { marginLeft: 0 } }}>
        <div style={{ ...styles.innerContainer, ...isMobile && { padding: 20 } }}>
          {errMsg ? <UnauthorizedPage message={errMsg} /> : (
            <Switch>
              <Route path="/dashboard" exact component={isMobile ? MobileHomePage : HomePage} />
              <Route path="/history" exact component={HistoryPage} />
              <Route path="/stocks" exact component={StocksPage} />
              <Route path="/stocks/:symbol" exact component={SingleStockPage} />
              <Route path="/news" exact component={NewsPage} />
              <Route path="/settings" exact component={SettingPage} />
              <Route path="/return" exact component={ReturnPage} />
            </Switch>
          )}

        </div>
      </div>
      <EditOrderModal />

    </>
  );
};

export default MainApp;
