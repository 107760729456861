import React from 'react';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReturnText from './ReturnText';
import MobileDashboardCell from './MobileDashboardCell';
import MonthlySpendingChart from './MonthlySpendingChart';

const styles = {
  equity: {
    fontWeight: 'bolder',
    fontSize: '2rem',
  },
};

const MobileHomePage = () => {
  const equity = useSelector((state) => state.user.equity);
  return (
    <Container fluid style={{ padding: 0 }}>
      <Row xs={1}>
        <Col>
          <h5 style={styles.equity}>{`$${equity.stocks.toFixed(2)}`}</h5>
          <h6>
            Today
            {' '}
            <ReturnText
              value={equity.todayReturn.equity}
              percentage={equity.todayReturn.percentage}
            />

          </h6>
          <h6>
            Total
            {' '}
            <ReturnText
              value={equity.totalReturn.equity}
              percentage={equity.totalReturn.percentage}
            />
          </h6>
        </Col>
      </Row>

      <Row xs={1} noGutters>
        <Col>
          <MobileDashboardCell>
            <MonthlySpendingChart />
          </MobileDashboardCell>
        </Col>
      </Row>
    </Container>
  );
};

export default MobileHomePage;
