import React from 'react';
import {
  Route, Switch,
  Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import AuthPage from './components/AuthPage';
import MainApp from './components/MainApp';
import ScrollToTop from './components/ScrollToTop';
import useCheckAuthenticated from './hooks/useCheckAuthenticated';


const App = () => {
  const loaded = useSelector((state) => Boolean(state.user.firstLoaded));
  const isAuthenticated = useCheckAuthenticated();
  return loaded && (
    <>
      <ScrollToTop />
      <Switch>
        <Route
          path="/(dashboard|history|stocks|news|settings|return)"
          render={(p) => {
            if (isAuthenticated) {
              return (<MainApp />);
            }
            return (<Redirect to={{ pathname: '/login', state: { nextPathName: p.location.pathname, nextSearch: p.location.search } }} />);
          }}
        />
        <Route
          path="/user/:uid"
          component={MainApp}
        />
        <Route
          path="/(login|signup)"
          exact
          render={({ location }) => {
            if (isAuthenticated) {
              return (
                <Redirect
                  to={
                      (location.state && location.state.nextPathName)
                        ? (location.state.nextPathName + location.state.nextSearch) : '/dashboard'
                    }
                />
              );
            }
            return <AuthPage />;
          }}
        />
        <Route path="*">
          <Redirect to="/dashboard" />
        </Route>
      </Switch>
    </>
  );
};

export default App;
