import {
  USER_SET_PROFILE, USER_SET_PORTFOLIO, USER_SET_ID, USER_SET_STOCKS_RETURN,
} from '../actionTypes';

const initialState = {
  orders: [],
  equity: 0,
  stocks: {},
  sectors: [],
  user: {},
  stocksDict: {},
  sectorsDict: {},
  topBottomPerforming: {},
  id: '',
  isAuthenticated: false,
  firstLoaded: false,
  stocksReturn: {
    todayReturn: {
      up: [],
      down: [],
    },
    fiveDaysReturn: {
      up: [],
      down: [],
    },
    oneMonthReturn: {
      up: [],
      down: [],
    },
    threeMonthsReturn: {
      up: [],
      down: [],
    },
    totalReturn: {
      up: [],
      down: [],
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_SET_PORTFOLIO: {
      const stocksDict = {};
      action.stocks.forEach((stock) => {
        stocksDict[stock.symbol] = stock;
      });
      const sectorsDict = {};
      action.sectors.forEach((sector) => {
        stocksDict[sector.name] = sector;
      });
      return {
        ...state,
        orders: action.orders,
        equity: action.equity,
        sectors: action.sectors,
        stocks: action.stocks,
        stocksDict,
        sectorsDict,
        topBottomPerforming: action.topBottomPerforming,
      };
    }
    case USER_SET_PROFILE:
      return {
        ...state,
        user: action.user,
      };
    case USER_SET_ID:
      return {
        ...state,
        id: action.uid,
        isAuthenticated: !!action.uid,
        firstLoaded: true,
      };
    case USER_SET_STOCKS_RETURN:
      return {
        ...state,
        stocksReturn: action.stocksReturn,
      };
    default:
      return state;
  }
};

export default reducer;
