import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SingleStockAboutCell from './SingleStockAboutCell';

const SingleStockCeoSection = ({ CEOCompensation }) => (
  <Row>
    <Col xs={3}>
      <SingleStockAboutCell title="Total" content={numeral(CEOCompensation.total).format('0.0a').toUpperCase()} />
    </Col>
    <Col xs={3}>
      <SingleStockAboutCell title="Salary" content={numeral(CEOCompensation.salary).format('0.0a').toUpperCase()} />
    </Col>
    <Col xs={3}>
      <SingleStockAboutCell
        title="Stock Awards"
        content={numeral(CEOCompensation.stockAwards).format('0.0a').toUpperCase()}
      />
    </Col>
    <Col xs={3}>
      <SingleStockAboutCell
        title="Other Incentives"
        content={numeral(CEOCompensation.nonEquityIncentives).format('0.0a').toUpperCase()}
      />
    </Col>
  </Row>
);

SingleStockCeoSection.propTypes = {
  CEOCompensation: PropTypes.object.isRequired,
};

export default SingleStockCeoSection;
