import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import palette from '../utils/palette';

const styles = {
  container: {
    borderRadius: 10,
    backgroundColor: 'white',
    border: `1px solid ${palette.borderColor}`,
  },
  title: {
    margin: 0,
    fontSize: '1rem',
    fontWeight: 'bolder',
  },
  headerSection: {
    padding: '10px 20px',
    color: palette.primary,
    borderBottom: `1px solid ${palette.borderColor}`,
    display: 'flex',
    justifyContent: 'space-between',
  },
  body: {
    padding: '15px 20px',
  },
  navigationButton: {
    padding: 0,
    color: palette.primary,
  },
};

const DashboardCell = ({
  title, children, navigationText, navigationAction,
}) => (
  <div style={styles.container}>
    {title && (
    <div style={styles.headerSection}>
      <h6 style={styles.title}>{title}</h6>
      {navigationText && (
      <Button
        size="sm"
        variant="link"
        onClick={navigationAction}
        style={styles.navigationButton}
      >
        {navigationText}
      </Button>
      )}
    </div>
    )}
    <div style={styles.body}>
      {children}
    </div>
  </div>
);

DashboardCell.defaultProps = {
  title: null,
  navigationText: null,
  navigationAction: null,
};

DashboardCell.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element.isRequired,
  navigationText: PropTypes.string,
  navigationAction: PropTypes.func,
};

export default DashboardCell;
