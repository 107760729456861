import React from 'react';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReturnText from './ReturnText';
import HomePageTopPerforming from './HomePageTopPerforming';
import DashboardCell from './DashboardCell';
import MonthlySpendingChart from './MonthlySpendingChart';
import HomePageRecentOrders from './HomePageRecentOrders';
import HomePageDiversity from './HomePageDiversity';

const styles = {
  row: {
    marginBottom: 20,
  },
};

const HomePage = () => {
  const equity = useSelector((state) => state.user.equity);

  const stocks = useSelector((state) => state.user.stocks);
  const sortedStockList = stocks
    .filter((stock) => stock.user.shares > 0)
    .sort((a, b) => b.user.diversity - a.user.diversity);
  const history = useHistory();
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Container fluid style={{ padding: 0 }}>
        <Row style={styles.row}>
          <Col xs={3}>
            <DashboardCell title="Today's return">
              <ReturnText
                value={equity.todayReturn.equity}
                percentage={equity.todayReturn.percentage}
              />
            </DashboardCell>
          </Col>
          <Col xs={3}>
            <DashboardCell title="Total return">
              <ReturnText
                value={equity.totalReturn.equity}
                percentage={equity.totalReturn.percentage}
              />
            </DashboardCell>
          </Col>
          <Col xs={3}>
            <DashboardCell title="Monthly return">
              <ReturnText
                value={equity.oneMonthReturn.equity}
                percentage={equity.oneMonthReturn.percentage}
              />
            </DashboardCell>
          </Col>
          <Col xs={3}>
            <DashboardCell title="Number of stocks">
              <span>
                {sortedStockList.length}
              </span>
            </DashboardCell>
          </Col>
        </Row>
        <Row style={styles.row}>
          <Col xs={8}>
            <Row style={styles.row}>
              <Col xs={12}>
                <DashboardCell title="Monthly spending">
                  <MonthlySpendingChart />
                </DashboardCell>
              </Col>
            </Row>
            <Row style={styles.row} xs={1}>
              <Col>
                <DashboardCell title="Diversity">
                  <HomePageDiversity />
                </DashboardCell>
              </Col>
            </Row>
          </Col>
          <Col xs={4}>
            <Row style={styles.row}>
              <Col xs={12}>
                <DashboardCell title="Top performing stocks">
                  <HomePageTopPerforming type="top" />
                </DashboardCell>
              </Col>
            </Row>
            <Row style={styles.row}>
              <Col xs={12}>
                <DashboardCell title="Bottom performing stocks">
                  <HomePageTopPerforming type="bottom" />
                </DashboardCell>
              </Col>
            </Row>
            <Row style={styles.row}>
              <Col xs={12}>
                <DashboardCell title="Recent orders" navigationText="View All >" navigationAction={() => history.push('/history')}>
                  <HomePageRecentOrders />
                </DashboardCell>
              </Col>
            </Row>
          </Col>
        </Row>


      </Container>
    </>
  );
};

export default HomePage;
