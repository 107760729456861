import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import StocksFilter from './StocksFilter';
import ReturnText from './ReturnText';

const styles = {
  ticker: {
    width: '10%',
  },
  companyName: {
    width: '30%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  currentPrice: {
    width: '10%',
  },
  today: {
    width: '15%',
  },
  averagePrice: {
    width: '10%',
  },
  shares: {
    width: '5%',
  },
  equity: {
    width: '10%',
  },
  diversity: {
    width: '10%',
  },
};

const StocksOwnedTable = ({ stocks }) => {
  let sortedStocks = stocks.sort((a, b) => b.equity - a.equity);

  const sectorFilter = useSelector((state) => state.stockFilter.sector);
  const selectedSectors = Object.keys(sectorFilter).filter((sector) => sectorFilter[sector]);

  if (selectedSectors.length > 0) {
    sortedStocks = sortedStocks.filter((stock) => sectorFilter[stock.profile.sector]);
  }

  const history = useHistory();
  const location = useLocation();
  const handleStockClicked = (symbol) => () => history.push(`stocks/${symbol}${location.search}`);

  return (
    <>
      <StocksFilter />

      <Table style={{ tableLayout: 'fixed' }} hover>
        <thead>
          <tr>
            <th style={styles.ticker}>Ticker</th>
            <th style={styles.companyName}>Company Name</th>
            <th style={styles.currentPrice}>Current Price</th>
            <th style={styles.today}>Today</th>
            <th style={styles.averagePrice}>Average</th>
            <th style={styles.shares}>Shares</th>
            <th style={styles.equity}>Equity</th>
            <th style={styles.diversity}>Diversity</th>
          </tr>
        </thead>
        <tbody>
          {sortedStocks.map((stock) => {
            const currentPrice = `$ ${stock.quote.latestPrice.toFixed(2)}`;
            const averagePrice = stock.user.averagePrice ? `$ ${stock.user.averagePrice.toFixed(2)}` : '-';
            const equity = `$ ${stock.user.equity.toFixed(2)}`;
            return (
              <tr
                key={stock.symbol}
                style={{ cursor: 'pointer' }}
                onClick={handleStockClicked(stock.symbol)}
              >
                <td>{stock.symbol}</td>
                <td style={styles.companyName}>{stock.profile.companyName}</td>
                <td>{currentPrice}</td>
                <td>
                  <ReturnText
                    value={stock.todayReturn.price}
                    percentage={stock.todayReturn.percentage}
                  />
                </td>
                <td>{averagePrice}</td>
                <td>{stock.user.shares}</td>
                <td>{equity}</td>
                <td>
                  {stock.user.diversity.toFixed(2)}
                  %
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

StocksOwnedTable.defaultProps = {
  stocks: [],
};

StocksOwnedTable.propTypes = {
  stocks: PropTypes.array,
};

export default StocksOwnedTable;
