import { uiStartLoading, uiStopLoading } from './ui';
import {
  USER_CREATE_ORDER,
  USER_GET_PORTFOLIO,
  USER_UPDATE_SETTINGS,
  USER_SIGN_IN,
  USER_SIGN_OUT,
} from '../loadingTypes';
import {
  createOrderAPI,
  updateOrderAPI,
  deleteOrderAPI,
  getCurrentUserAPI,
  updateSettingsAPI,
  getPortfolioAPI,
  createUserAPI,
} from '../../apis/user';
import {
  USER_SET_PORTFOLIO, USER_SET_PROFILE, USER_SET_ID, USER_SET_STOCKS_RETURN,
} from '../actionTypes';
import { auth } from '../../apis/firebase';
import { toggleShowToast } from './toast';

export const createOrder = (order) => async (dispatch, getState) => {
  dispatch(uiStartLoading(USER_CREATE_ORDER));
  const { id } = getState().user;
  try {
    await createOrderAPI(id, order);
    dispatch(toggleShowToast(true, 'Order created!'));
    dispatch(uiStopLoading(USER_CREATE_ORDER));
  } catch (e) {
    dispatch(uiStopLoading(USER_CREATE_ORDER));
    throw (e);
  }
};

export const deleteOrder = (oid, stockSymbol) => async (dispatch, getState) => {
  dispatch(uiStartLoading(USER_CREATE_ORDER));
  const { id } = getState().user;
  try {
    await deleteOrderAPI(id, oid, stockSymbol);
    dispatch(toggleShowToast(true, 'Order deleted.'));
    dispatch(uiStopLoading(USER_CREATE_ORDER));
  } catch (e) {
    dispatch(uiStopLoading(USER_CREATE_ORDER));
    throw (e);
  }
};

export const updateOrder = (data, oid) => async (dispatch, getState) => {
  dispatch(uiStartLoading(USER_CREATE_ORDER));
  const { id } = getState().user;
  try {
    await updateOrderAPI(id, data, oid);
    dispatch(toggleShowToast(true, 'Order updated.'));
    dispatch(uiStopLoading(USER_CREATE_ORDER));
  } catch (e) {
    dispatch(toggleShowToast(true, 'Something went wrong.'));
    dispatch(uiStopLoading(USER_CREATE_ORDER));
    throw (e);
  }
};

export const getSelfPortfolio = () => async (dispatch, getState) => {
  dispatch(uiStartLoading(USER_GET_PORTFOLIO));
  const { id } = getState().user;
  try {
    const {
      orders, stocks, sectors, equity, topBottomPerforming,
    } = await getPortfolioAPI(id);
    const stocksReturn = {
      todayReturn: {
        up: stocks.filter((stock) => stock.user.todayReturn.equity > 0),
        down: stocks.filter((stock) => stock.user.todayReturn.equity < 0),
      },
      fiveDaysReturn: {
        up: stocks.filter((stock) => stock.user.fiveDaysReturn.equity > 0),
        down: stocks.filter((stock) => stock.user.fiveDaysReturn.equity < 0),
      },
      oneMonthReturn: {
        up: stocks.filter((stock) => stock.user.oneMonthReturn.equity > 0),
        down: stocks.filter((stock) => stock.user.oneMonthReturn.equity < 0),
      },
      threeMonthsReturn: {
        up: stocks.filter((stock) => stock.user.threeMonthsReturn.equity > 0),
        down: stocks.filter((stock) => stock.user.threeMonthsReturn.equity < 0),
      },
      totalReturn: {
        up: stocks.filter((stock) => stock.user.totalReturn.equity > 0),
        down: stocks.filter((stock) => stock.user.totalReturn.equity < 0),
      },
    };
    dispatch({
      type: USER_SET_PORTFOLIO,
      orders,
      stocks,
      sectors,
      equity,
      topBottomPerforming,
    });
    const user = await getCurrentUserAPI(id);
    dispatch({
      type: USER_SET_PROFILE,
      user,
    });
    dispatch({
      type: USER_SET_STOCKS_RETURN,
      stocksReturn,
    });
    dispatch(uiStopLoading(USER_GET_PORTFOLIO));
  } catch (e) {
    dispatch(uiStopLoading(USER_GET_PORTFOLIO));
    throw (e);
  }
};

export const getOthersPortfolio = (uid) => async (dispatch) => {
  dispatch(uiStartLoading(USER_GET_PORTFOLIO));
  try {
    const {
      orders, stocks, sectors, equity, topBottomPerforming,
    } = await getPortfolioAPI(uid, true);
    dispatch({
      type: USER_SET_PORTFOLIO,
      orders,
      stocks,
      sectors,
      equity,
      topBottomPerforming,
    });
    dispatch(uiStopLoading(USER_GET_PORTFOLIO));
  } catch (e) {
    dispatch(uiStopLoading(USER_GET_PORTFOLIO));
    throw (e);
  }
};

export const updateSettings = (data) => async (dispatch, getState) => {
  dispatch(uiStartLoading(USER_UPDATE_SETTINGS));
  try {
    const { id } = getState().user;
    await updateSettingsAPI(id, data);
    dispatch(toggleShowToast(true, 'User settings updated.'));
    dispatch(uiStopLoading(USER_UPDATE_SETTINGS));
  } catch (e) {
    dispatch(uiStopLoading(USER_UPDATE_SETTINGS));
    throw (e);
  }
};


export const checkAuthenticated = () => (dispatch) => auth.onAuthStateChanged((user) => {
  dispatch({
    type: USER_SET_ID,
    uid: user && user.uid,
  });
});

export const signIn = (email, password) => async (dispatch) => {
  dispatch(uiStartLoading(USER_SIGN_IN));
  try {
    await auth.signInWithEmailAndPassword(email, password);
    dispatch(uiStopLoading(USER_SIGN_IN));
  } catch (e) {
    dispatch(uiStopLoading(USER_SIGN_IN));
    throw (e);
  }
};

export const signUp = (credentials) => async (dispatch) => {
  dispatch(uiStartLoading(USER_SIGN_IN));
  try {
    const {
      email, password, firstName, lastName,
    } = credentials;
    const userCredential = await auth.createUserWithEmailAndPassword(email, password);
    await createUserAPI(userCredential.user.uid, firstName, lastName);
    dispatch(uiStopLoading(USER_SIGN_IN));
  } catch (e) {
    dispatch(uiStopLoading(USER_SIGN_IN));
    throw (e);
  }
};

export const signOut = () => async (dispatch) => {
  dispatch(uiStartLoading(USER_SIGN_OUT));
  await auth.signOut();
  dispatch(uiStopLoading(USER_SIGN_OUT));
};
