import React from 'react';
import PropTypes from 'prop-types';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import ListGroup from 'react-bootstrap/ListGroup';

const customToggleStyles = {
  container: {
    cursor: 'pointer',
    borderRadius: 5,
    fontSize: '0.9rem',
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
};

const CustomToggle = ({
  entry, eventKey, last, onClick, active,
}) => {
  const decoratedOnClick = useAccordionToggle(eventKey, onClick);

  return (
    <ListGroup.Item
      style={{
        ...customToggleStyles.container,
        ...last && { marginBottom: 0 },
        ...active && { marginBottom: 5, borderRadius: '5px 5px 0px 0px' },
      }}
      onClick={decoratedOnClick}
    >
      <span>
        {entry.name}
      </span>
      <span>
        <span style={{ marginRight: 5 }}>{entry.value}</span>
        <FontAwesomeIcon icon={active ? faAngleUp : faAngleDown} />
      </span>
    </ListGroup.Item>
  );
};

CustomToggle.defaultProps = {
  last: false,
  onClick: null,
  active: false,
};

CustomToggle.propTypes = {
  entry: PropTypes.object.isRequired,
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  last: PropTypes.bool,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

const styles = {
  listGroup: {
    padding: 15,
    backgroundColor: '#ededed',
    borderRadius: 10,
  },
  collapse: {
    fontSize: '0.85rem',
    padding: 10,
    paddingTop: 0,
    paddingBottom: 0,
  },
  collapseListItem: {
    color: 'gray',
    padding: 0,
    paddingTop: 5,
    paddingBottom: 5,
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const DiversityAccordion = ({ activeKey, data, onItemClick }) => (
  <Accordion activeKey={activeKey}>
    <ListGroup variant="flush" style={styles.listGroup}>
      {data.map((dataEntry, index) => {
        const last = index === data.length - 1;
        const active = activeKey === index;
        return (
          <div key={dataEntry.name}>
            <CustomToggle
              entry={dataEntry}
              last={last}
              eventKey={index}
              onClick={() => onItemClick(index)}
              active={active}
            />
            <Accordion.Collapse eventKey={index}>
              <ListGroup.Item
                style={{
                  ...styles.collapse,
                  ...!last && { marginBottom: 15 },
                }}
              >
                <ListGroup>
                  {(dataEntry.collapseData || []).map((collapseDataEntry) => (
                    <ListGroup.Item
                      key={collapseDataEntry.name}
                      style={styles.collapseListItem}
                    >
                      <span>
                        {collapseDataEntry.name}
                      </span>
                      <span>
                        {collapseDataEntry.value}
                      </span>
                    </ListGroup.Item>
                  ))}
                </ListGroup>

              </ListGroup.Item>
            </Accordion.Collapse>
          </div>
        );
      })}
    </ListGroup>
  </Accordion>
);

DiversityAccordion.defaultProps = {
  data: [],
  onItemClick: null,
};

DiversityAccordion.propTypes = {
  activeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  data: PropTypes.array,
  onItemClick: PropTypes.func,
};

export default DiversityAccordion;
