import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FlexHeightImage from './FlexHeightImage';
import palette from '../utils/palette';

const styles = {
  container: {
    paddingTop: 20,
    paddingBottom: 20,
    cursor: 'pointer',
  },
  hoveredHeadline: {
    color: palette.secondary,
  },
  hoveredBackground: {
    // backgroundColor: '#f0f0f0',
  },
  headline: {
    fontSize: '1rem',
    fontWeight: 'bolder',
  },
  source: {
    fontSize: '0.8rem',
    marginRight: 5,
    fontWeight: 'bolder',
  },
  datetime: {
    color: 'gray',
    fontSize: '0.7rem',
    float: 'right',
  },
};

const NewsBanner = ({ news }) => {
  const [hovered, setHovered] = useState(false);

  const handleHover = (isHovered) => () => {
    setHovered(isHovered);
  };
  const handleNewsClicked = () => {
    window.open(news.url, '_blank');
  };
  return (
    <Row
      style={{ ...styles.container, ...(hovered && styles.hoveredBackground) }}
      onMouseEnter={handleHover(true)}
      onMouseLeave={handleHover(false)}
      onClick={handleNewsClicked}
      noGutters
    >
      <Col xs={5} style={{ display: 'flex', alignItems: 'center', paddingRight: 10 }}>
        <FlexHeightImage image={news.image} height="70%" />
      </Col>
      <Col xs={7}>
        <h6>
          <span style={styles.source}>{news.source}</span>
          <span style={styles.datetime}>{moment(news.datetime).fromNow(true)}</span>
        </h6>
        <h6
          style={{ ...styles.headline, ...(hovered && styles.hoveredHeadline) }}
        >
          {news.headline}
        </h6>
      </Col>
    </Row>
  );
};

NewsBanner.propTypes = {
  news: PropTypes.object.isRequired,
};

export default NewsBanner;
