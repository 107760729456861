import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
  },
};

const SpinningPage = () => (
  <div style={styles.container}>
    <Spinner variant="secondary" animation="grow" />
  </div>
);

export default SpinningPage;
