import React from 'react';
import PropTypes from 'prop-types';

const UnauthorizedPage = ({ message }) => (
  <h6>{message}</h6>
);

UnauthorizedPage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default UnauthorizedPage;
