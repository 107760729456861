import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import Image from 'react-bootstrap/Image';
import Toast from 'react-bootstrap/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faThLarge, faChartLine, faNewspaper, faExchangeAlt, faHistory,
} from '@fortawesome/free-solid-svg-icons';
import palette from '../utils/palette';
import { toggleShowToast } from '../store/actions/toast';
import { showModal } from '../store/actions/editOrder';
import logo from '../assets/images/logo.png';
import padding from '../utils/padding';
import DefaultButton from './DefaultButton';
import SidebarListItem from './SidebarListItem';

const styles = {
  container: {
    width: 260,
    zIndex: 1031,
    paddingBottom: 60,
    top: 0,
    height: '100vh',
    position: 'fixed',
    backgroundColor: 'white',
    borderRight: `${palette.borderColor} solid 1px`,
  },
  logoArea: {
    padding: padding.sidebarPaddingLeft,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  brand: {
    fontWeight: 'bolder',
    fontSize: '1.4rem',
    margin: 0,
    marginLeft: 20,
    color: 'black',
  },
  headerField: {
    padding: 20,
    paddingBottom: 10,
    paddingTop: 30,
  },
  equity: {
    fontWeight: 'bolder',
    fontSize: '1.5rem',
  },
  toastArea: {
    position: 'absolute',
    bottom: 0,
    padding: 10,
  },
};

const Sidebar = () => {
  const showToast = useSelector((state) => state.toast.show);
  const toastContent = useSelector((state) => state.toast.content);
  const dispatch = useDispatch();
  return (
    <div style={styles.container}>
      <a href="#/dashboard" style={styles.logoArea}>
        <Image src={logo} width={35} height={35} />
        <h5 style={styles.brand}>Finance</h5>
      </a>
      <div style={{ padding: padding.sidebarPaddingLeft }}>
        <DefaultButton
          variant="outline-secondary"
          block
          onClick={() => dispatch(showModal())}
        >
          <span>
            Create Order
            <FontAwesomeIcon icon={faPlus} style={{ marginLeft: 10 }} />
          </span>
        </DefaultButton>
      </div>
      <ListGroup>
        <SidebarListItem icon={faThLarge} url="dashboard">Dashboard</SidebarListItem>
        <SidebarListItem icon={faChartLine} url="stocks">Stocks</SidebarListItem>
        <SidebarListItem icon={faNewspaper} url="news">News</SidebarListItem>
        <SidebarListItem icon={faExchangeAlt} url="return">Return</SidebarListItem>
        <SidebarListItem icon={faHistory} url="history">History</SidebarListItem>
      </ListGroup>
      <div style={styles.toastArea}>
        <Toast
          style={{ backgroundColor: palette.third, color: palette.primary }}
          onClose={() => dispatch(toggleShowToast(false))}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Body>{toastContent}</Toast.Body>
        </Toast>
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
