import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  container: {
    marginBottom: 20,
  },
  title: {
    marginBottom: '0.2rem',
    fontSize: '0.9rem',
  },
  content: {
    fontSize: '0.9rem',
  },
};

const SingleStockAboutCell = ({ title, content }) => (
  <div style={styles.container}>
    <h6 style={styles.title}>{title}</h6>
    <h6 style={styles.content}>{content}</h6>
  </div>
);

SingleStockAboutCell.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default SingleStockAboutCell;
