import React from 'react';
import PropTypes from 'prop-types';
import SingleStockPeersItem from './SingleStockPeersItem';

const SingleStockPeers = ({ peers }) => (
  <>
    {peers.map((symbol) => <SingleStockPeersItem key={symbol} symbol={symbol} />)}
  </>
);

SingleStockPeers.defaultProps = {
  peers: [],
};

SingleStockPeers.propTypes = {
  peers: PropTypes.array,
};

export default SingleStockPeers;
