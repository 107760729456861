import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { showModal } from '../store/actions/editOrder';

const HistoryPage = () => {
  const orders = useSelector((state) => state.user.orders);
  const stocksDict = useSelector((state) => state.user.stocksDict);
  const dispatch = useDispatch();
  const handleOrderClicked = (order) => () => {
    dispatch(showModal(order));
  };
  return (
    <>
      <Helmet>
        <title>History</title>
      </Helmet>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Ticker</th>
            <th>Company Name</th>
            <th>Date</th>
            <th>Shares</th>
            <th>Price</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.oid} style={{ cursor: 'pointer' }} onClick={handleOrderClicked(order)}>
              <td>{order.stockSymbol}</td>
              <td>
                {stocksDict[order.stockSymbol] && stocksDict[order.stockSymbol].profile.companyName}
              </td>
              <td>{moment(order.date).local().format('L')}</td>
              <td>{order.shares}</td>
              <td>{order.price}</td>
              <td>{order.action}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default HistoryPage;
