import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const NavLink = ({ children, url }) => {
  const history = useHistory();
  const { location: { pathname } } = history;
  return <Nav.Link eventKey={url} active={pathname === url}>{children}</Nav.Link>;
};

const styles = {
  container: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  brand: {
    cursor: 'pointer',
    color: '#88D498',
  },
};

const MobileNavbar = () => {
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();
  const handleNavSelect = (eventKey) => {
    setExpanded(false);
    history.push(eventKey);
  };
  return (
    <>
      <Navbar bg="primary" variant="dark" fixed="top" expand={false} expanded={expanded} onSelect={handleNavSelect}>
        <Navbar.Brand style={styles.brand} onClick={() => history.push('/')}>
          MyPortfolio
        </Navbar.Brand>
        <Navbar.Toggle style={{ color: 'white' }} onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse>
          <Nav>
            <NavLink url="/">Home</NavLink>
            <NavLink url="/stocks">Stocks</NavLink>
            <NavLink url="/news">News</NavLink>
            <NavLink url="/return">Return</NavLink>
            <NavLink url="/history">History</NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default MobileNavbar;
