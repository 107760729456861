import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Stock from '../models/stock';

const useFetchQuote = (symbol) => {
  const [loading, setLoading] = useState(true);
  const [todayReturn, setTodayReturn] = useState(null);
  const stocksDict = useSelector((state) => state.user.stocksDict);
  useEffect(() => {
    const fetch = async () => {
      let stock;
      if (stocksDict[symbol]) {
        stock = stocksDict[symbol];
      } else {
        stock = new Stock(symbol);
        await stock.getQuote();
      }
      setTodayReturn(stock.todayReturn);
      setLoading(false);
    };
    fetch();
  }, [symbol, stocksDict]);
  return { todayReturn, loading };
};

export default useFetchQuote;
