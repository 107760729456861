
class Order {
  constructor({
    date, action, price, stockSymbol, shares, note,
  }, oid, creator) {
    this.oid = oid; // String
    this.date = date; // Date
    this.action = action; // 'buy' or 'sell'
    this.price = price; // Number
    this.stockSymbol = stockSymbol; // Stock
    this.shares = shares; // Number
    this.creator = creator;
    this.note = note;
  }

  toJson() {
    return {
      date: this.date,
      action: this.action,
      price: this.price,
      stockSymbol: this.stockSymbol,
      shares: this.shares,
      oid: this.oid,
      creator: this.creator,
      note: this.note,
    };
  }
}

export default Order;
