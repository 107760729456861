import React from 'react';
import PropTypes from 'prop-types';
import palette from '../utils/palette';

const ReturnText = ({
  value, percentage, signed, colored, style,
}) => {
  let color = 'black';
  if (colored) {
    if (value >= 0) {
      color = palette.upGreenText;
    } else {
      color = palette.downRedText;
    }
  }
  const sign = (signed && value > 0) ? '+' : '';
  return (
    <span style={{ color, ...style }}>
      {`${sign}$${value.toFixed(2)} (${sign}${percentage.toFixed(2)}%)`}
    </span>
  );
};

ReturnText.defaultProps = {
  signed: true,
  colored: true,
  style: {},
};

ReturnText.propTypes = {
  value: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
  signed: PropTypes.bool,
  colored: PropTypes.bool,
  style: PropTypes.object,
};

export default ReturnText;
