import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

const styles = {
  container: {
    color: 'gray',
    marginRight: 20,
    padding: 0,
  },
  selected: {
    color: 'black',
    fontWeight: 'bolder',
  },
};

const DateRangeButton = ({ onClick, selected, children }) => (
  <Button
    variant="link"
    size="sm"
    style={{ ...styles.container, ...selected && styles.selected }}
    onClick={onClick}
  >
    {children}
  </Button>
);


DateRangeButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  children: PropTypes.string.isRequired,
};

export default DateRangeButton;
