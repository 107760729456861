import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  PieChart, Pie, ResponsiveContainer, Sector,
} from 'recharts';
import palette from '../utils/palette';
import useLayoutWidth from '../hooks/useLayoutWidth';

const ActiveShape = ({
  cx, cy, innerRadius, outerRadius, startAngle, endAngle, payload, fill, percent,
}) => (
  <g>
    <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
    <text x={cx} y={cy} dy={25} textAnchor="middle" fontSize="smaller" fill={fill}>{`${(percent * 100).toFixed(2)}%`}</text>
    <Sector
      cx={cx}
      cy={cy}
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
    <Sector
      cx={cx}
      cy={cy}
      startAngle={startAngle}
      endAngle={endAngle}
      innerRadius={outerRadius * 1.4}
      outerRadius={outerRadius * 1.5}
      fill={fill}
    />
  </g>
);

ActiveShape.propTypes = {
  cx: PropTypes.number.isRequired,
  cy: PropTypes.number.isRequired,
  innerRadius: PropTypes.number.isRequired,
  outerRadius: PropTypes.number.isRequired,
  startAngle: PropTypes.number.isRequired,
  endAngle: PropTypes.number.isRequired,
  fill: PropTypes.number.isRequired,
  payload: PropTypes.object.isRequired,
  percent: PropTypes.number.isRequired,
};

const TwoLevelPieChart = ({
  outerData, innerData, activeIndex, onInnerPieEnter,
}) => {
  const chartRef = useRef();
  const width = useLayoutWidth(chartRef);
  const handlePieEnter = (data, index) => {
    onInnerPieEnter(index);
  };
  return (
    <div ref={chartRef} style={{ height: width }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            isAnimationActive={false}
            activeIndex={activeIndex}
            activeShape={ActiveShape}
            data={innerData}
            dataKey="value"
            innerRadius={width * 0.25}
            outerRadius={width * 0.32}
            fill={palette.primary}
            onClick={handlePieEnter}
          />
          <Pie
            isAnimationActive={false}
            data={outerData}
            dataKey="value"
            innerRadius={width * 0.35}
            outerRadius={width * 0.42}
            fill={palette.secondary}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>

  );
};

TwoLevelPieChart.propTypes = {
  outerData: PropTypes.array.isRequired,
  innerData: PropTypes.array.isRequired,
  activeIndex: PropTypes.number.isRequired,
  onInnerPieEnter: PropTypes.func.isRequired,
};

export default TwoLevelPieChart;
