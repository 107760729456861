import { EDIT_ORDER_SHOW_MODAL, EDIT_ORDER_CLOSE_MODAL } from '../actionTypes';

export const showModal = (order = null) => (dispatch) => {
  dispatch({
    type: EDIT_ORDER_SHOW_MODAL,
    order,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: EDIT_ORDER_CLOSE_MODAL,
  });
};
