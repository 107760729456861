import {
  createStore, combineReducers, compose, applyMiddleware,
} from 'redux';
import thunk from 'redux-thunk';

import ui from './reducers/ui';
import user from './reducers/user';
import editOrder from './reducers/editOrder';
import stockFilter from './reducers/stockFilter';
import toast from './reducers/toast';

const rootReducer = combineReducers({
  ui,
  user,
  editOrder,
  stockFilter,
  toast,
});

let composeEnhancers = compose;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const configureStore = () => createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default configureStore;
