import { useMediaQuery } from 'react-responsive';

const useScreenSize = () => {
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });
  const isSmallScreen = useMediaQuery({
    query: '(max-width: 900px)',
  });
  const isMediumScreen = useMediaQuery({
    query: '(max-width: 1300px)',
  });
  return {
    isMobile,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen: !isMobile && !isMediumScreen,
  };
};

export default useScreenSize;
