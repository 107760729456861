import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { iexToken } from '../utils/secrets';


const iexUrl = 'https://cloud.iexapis.com/stable';

const fetchGet = async (url) => {
  const res = await fetch(url);
  if (!res.ok) {
    throw new Error(res.statusText);
  }
  return res.json();
};

const useFetchStockPrice = (symbol) => {
  const [prices, setPrices] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      const params = {
        token: iexToken,
        chartIEXOnly: true,
        chartInterval: 5,
      };
      try {
        const payload = await fetchGet(`${iexUrl}/stock/${symbol}/intraday-prices?${queryString.stringify(params)}`);
        setPrices(payload);
      } catch {
        setPrices([]);
      }
    };
    fetch();
  }, [symbol]);
  return prices;
};

export default useFetchStockPrice;
