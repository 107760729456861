import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TwoLevelPieChart from './TwoLevelPieChart';
import DiversityAccordion from './DiversityAccordion';

const HomePageDiversity = () => {
  const stocksDict = useSelector((state) => state.user.stocksDict);
  const sectors = useSelector((state) => state.user.sectors);
  sectors.sort((a, b) => b.diversity - a.diversity);
  const [activeIndex, setActiveIndex] = useState(0);

  const getStockData = (symbol) => ({ name: symbol, value: stocksDict[symbol].user.equity });
  const stocksData = sectors
    .map((sector) => sector.stocks.map((symbol) => getStockData(symbol)))
    .flat();
  const sectorsData = sectors.map((sector) => ({ name: sector.name, value: sector.equity }));
  const handleUpdateActiveSector = (sectorIndex) => {
    if (sectorIndex === activeIndex) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(sectorIndex);
    }
  };
  return (
    <Row>
      <Col xs={7}>
        <TwoLevelPieChart
          outerData={stocksData}
          innerData={sectorsData}
          activeIndex={activeIndex}
          onInnerPieEnter={handleUpdateActiveSector}
        />
      </Col>
      <Col xs={5}>
        <DiversityAccordion
          activeKey={activeIndex}
          data={sectors.map((sector) => ({
            name: sector.name,
            value: `${sector.diversity.toFixed(2)}%`,
            collapseData: sector.stocks.map((symbol) => ({
              name: symbol,
              value: `${stocksDict[symbol].user.diversity.toFixed(2)}%`,
            })),
          }))}
          onItemClick={handleUpdateActiveSector}
        />
      </Col>
    </Row>
  );
};

export default HomePageDiversity;
