import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { updateSettings } from '../store/actions/user';
import { USER_UPDATE_SETTINGS } from '../store/loadingTypes';
import DefaultButton from './DefaultButton';

const SettingPage = () => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.ui.isLoading[USER_UPDATE_SETTINGS]);
  const [controls, setControls] = useState({
    public: {
      value: Boolean(user.settings && user.settings.public),
    },
  });
  const handleInputChange = (key) => ({ target: { value } }) => {
    let newValue = value;
    if (key === 'public') {
      newValue = !controls.public.value;
    }
    setControls({
      ...controls,
      [key]: {
        ...controls[key],
        value: newValue,
      },
    });
  };
  const [errMsg, setErrMsg] = useState();
  const handleFormSubmitted = async (event) => {
    event.preventDefault();
    const settings = {
      public: controls.public.value,
    };
    try {
      await dispatch(updateSettings(settings));
    } catch (e) {
      setErrMsg(e.message);
    }
  };
  return (
    <Form onSubmit={handleFormSubmitted}>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      {errMsg && <Alert variant="danger">{errMsg}</Alert>}
      <div style={{ minHeight: 300 }}>
        <Form.Group>
          <Form.Label>Permissions</Form.Label>
          <Form.Check
            type="switch"
            id="custom-switch"
            checked={controls.public.value}
            label="Allow people to view your portfolio"
            onChange={handleInputChange('public')}
          />
        </Form.Group>
      </div>
      <DefaultButton type="submit" disabled={isLoading}>Save</DefaultButton>
    </Form>
  );
};

export default SettingPage;
