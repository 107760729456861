/**
 *
 * @param {Date} dateToBeChecked
 * @param {Number} numberOfDays
 */

export const isLongerThanDays = (dateToBeChecked, numberOfDays) => {
  const currentDate = new Date();
  const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
  if (currentDate - dateToBeChecked < oneDayInMilliseconds * numberOfDays) {
    return false;
  }
  return true;
};
