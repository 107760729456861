import { EDIT_ORDER_SHOW_MODAL, EDIT_ORDER_CLOSE_MODAL } from '../actionTypes';

const initialState = {
  order: null,
  showModal: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_ORDER_SHOW_MODAL:
      return {
        ...state,
        showModal: true,
        order: action.order,
      };
    case EDIT_ORDER_CLOSE_MODAL:
      return {
        ...state,
        order: null,
        showModal: false,
      };
    default:
      return state;
  }
};

export default reducer;
