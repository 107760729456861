import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FilterTag from './FilterTag';
import { toggleTag } from '../store/actions/stockFilter';

const styles = {
  container: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: '1rem',
  },
};

const StocksFilter = () => {
  const dispatch = useDispatch();
  const sectors = useSelector((state) => state.user.sectors);
  const sectorFilter = useSelector((state) => state.stockFilter.sector);
  const handleTagClicked = (tag) => () => {
    dispatch(toggleTag(tag));
  };
  return (
    <div style={styles.container}>
      <h5 style={styles.sectionTitle}>Sectors</h5>
      {sectors.map((sector) => (
        <FilterTag
          key={sector.name}
          onClick={handleTagClicked(sector.name)}
          selected={sectorFilter[sector.name]}
        >
          {sector.name}
        </FilterTag>
      ))}

    </div>
  );
};

export default StocksFilter;
