import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useLayoutWidth from '../hooks/useLayoutWidth';
import palette from '../utils/palette';

const styles = {
  container: {
    backgroundColor: 'white',
    border: `1px solid ${palette.borderColor}`,
    borderRadius: 10,
    padding: 20,
  },
};

const MobileDashboardCell = ({ children, square }) => {
  const divRef = useRef();
  const width = useLayoutWidth(divRef);
  return (
    <div ref={divRef} style={{ ...styles.container, ...square && { height: width } }}>
      {children}
    </div>
  );
};

MobileDashboardCell.defaultProps = {
  square: false,
  children: null,
};

MobileDashboardCell.propTypes = {

  square: PropTypes.bool,
  children: PropTypes.element,
};

export default MobileDashboardCell;
