import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import StocksTable from './StocksOwnedTable';
import StocksNoLongerOwnedTable from './StocksNoLongerOwnedTable';

const StocksPage = () => {
  const stocks = useSelector((state) => state.user.stocks);
  const stocksOwned = stocks.filter((stock) => stock.user.shares > 0);
  const stocksNoLongerOwned = stocks.filter((stock) => stock.user.shares === 0);
  return (
    <>
      <Helmet>
        <title>Stocks</title>
      </Helmet>
      <StocksTable stocks={stocksOwned} />
      <StocksNoLongerOwnedTable stocks={stocksNoLongerOwned} />
    </>
  );
};


export default StocksPage;
