import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { showModal } from '../store/actions/editOrder';

const SingleStockHistory = ({ orders }) => {
  const dispatch = useDispatch();
  const handleOrderClicked = (order) => () => {
    dispatch(showModal(order));
  };
  return (
    <Table hover borderless style={{ tableLayout: 'fixed', cursor: 'pointer' }}>
      <thead>
        <tr>
          <th style={{ width: '25%' }}>Price</th>
          <th style={{ width: '35%' }}>Date</th>
          <th style={{ width: '20%' }}>Action</th>
          <th style={{ width: '20%' }}>Shares</th>
        </tr>
      </thead>
      <tbody>
        {orders.map((order) => (
          <tr key={order.oid} onClick={handleOrderClicked(order)}>
            <td>{`$${order.price}`}</td>
            <td>{moment(new Date(order.date)).local().format('L')}</td>
            <td>{order.action}</td>
            <td>{order.shares}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

SingleStockHistory.defaultProps = {
  orders: [],
};

SingleStockHistory.propTypes = {
  orders: PropTypes.array,
};

export default SingleStockHistory;
