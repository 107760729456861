import moment from 'moment';
import User from '../models/user';

export const getCurrentUserAPI = async (uid) => {
  const user = new User(uid);
  await user.get();
  return user.toJson();
};

export const createOrderAPI = async (uid, payload) => {
  const user = new User(uid);
  if (!moment(new Date(payload.date)).isValid()) {
    throw new Error('Invalid date.');
  }
  if (!payload.stockSymbol) {
    throw new Error('Missing stock symbol.');
  }
  if (!payload.price || !payload.price > 0) {
    throw new Error('Price can only be positive number.');
  }
  if (!/^\+?([1-9]\d*)$/.test(payload.shares)) {
    throw new Error('Shares can only be positive integer.');
  }
  if (payload.action !== 'buy' && payload.action !== 'sell') {
    throw new Error('Invalid action.');
  }
  await user.createOrder({
    date: payload.date,
    stockSymbol: payload.stockSymbol,
    price: payload.price,
    shares: payload.shares,
    action: payload.action,
  });
};

export const updateOrderAPI = async (uid, order, oid) => {
  const user = new User(uid);
  await user.updateOrder(oid, order);
};

export const deleteOrderAPI = async (uid, oid, stockSymbol) => {
  const user = new User(uid);
  await user.deleteOrder(oid, stockSymbol);
};

export const getPortfolioAPI = async (uid, shouldCheckPublic = false) => {
  const user = new User(uid);
  if (shouldCheckPublic) {
    const isPublic = await user.checkPublic();
    if (!isPublic) {
      throw new Error('This Portfolio is not public.');
    }
  }
  return user.getPortfolio();
};

export const updateSettingsAPI = async (uid, settings) => {
  const user = new User(uid);
  await user.updateSettings(settings);
};

export const createUserAPI = async (uid, firstName, lastName) => {
  const user = new User(uid);
  await user.createWithName(firstName, lastName);
};
