import React, { useRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  PieChart, Pie, Cell, ResponsiveContainer, Tooltip,
} from 'recharts';

const COLORS = [
  '#003f5c',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
];

const HomePagePieChart = ({
  data, animated, isPercentage,
}) => {
  const divRef = useRef();
  const targetRef = useRef();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    setWidth(divRef.current.offsetWidth);
    setHeight(divRef.current.offsetHeight);
  }, []);
  const radius = Math.min(width, height) * 0.4;
  return (
    <div ref={divRef} style={{ height: '100%' }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            outerRadius={radius}
            fill="#8884d8"
            isAnimationActive={animated}
          >
            {data.map((entry, index) => (
              <Cell
                ref={targetRef}
                key={entry}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip formatter={(value, name) => ([`${isPercentage ? '' : '$'}${value.toFixed(2)}${isPercentage ? '%' : ''}`, name])} />

        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

HomePagePieChart.propTypes = {
  data: PropTypes.array.isRequired,
  animated: PropTypes.bool.isRequired,
  isPercentage: PropTypes.bool.isRequired,
};

export default HomePagePieChart;
