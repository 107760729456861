import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyAE4nMbpYq3U_5zmJozfB16xYOhTYc_SHY',
  authDomain: 'investment-portfolio-5b1fb.firebaseapp.com',
  databaseURL: 'https://investment-portfolio-5b1fb.firebaseio.com',
  projectId: 'investment-portfolio-5b1fb',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const auth = firebaseApp.auth();
export const db = firebaseApp.firestore();
export const functions = firebaseApp.functions();
