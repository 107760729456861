import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ReturnText from './ReturnText';

const HomePageTopPerforming = ({ type }) => {
  const topBottomPerforming = useSelector((state) => state.user.topBottomPerforming);
  const stocksDict = useSelector((state) => state.user.stocksDict);

  const history = useHistory();
  const location = useLocation();
  const handleStockClicked = (symbol) => () => history.push(`/stocks/${symbol}${location.search}`);
  return (
    <Tabs defaultActiveKey="percentage" transition={false} style={{ marginTop: 10 }}>
      <Tab eventKey="percentage" title="Percentage">
        <Table hover borderless>
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Return</th>
            </tr>
          </thead>
          <tbody>
            {topBottomPerforming[type].percentage.map((symbol) => (
              <tr key={symbol} onClick={handleStockClicked(symbol)} style={{ cursor: 'pointer' }}>
                <td>{symbol}</td>
                <td>
                  <ReturnText
                    value={stocksDict[symbol].user.totalReturn.equity}
                    percentage={stocksDict[symbol].user.totalReturn.percentage}
                  />

                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Tab>
      <Tab eventKey="Equity" title="Equity">
        <Table hover borderless>
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Return</th>
            </tr>
          </thead>
          <tbody>
            {topBottomPerforming[type].equity.map((symbol) => (
              <tr key={symbol} onClick={handleStockClicked(symbol)} style={{ cursor: 'pointer' }}>
                <td>{symbol}</td>
                <td>
                  <ReturnText
                    value={stocksDict[symbol].user.totalReturn.equity}
                    percentage={stocksDict[symbol].user.totalReturn.percentage}
                  />

                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Tab>
    </Tabs>
  );
};

HomePageTopPerforming.propTypes = {
  type: PropTypes.string.isRequired,
};

export default HomePageTopPerforming;
