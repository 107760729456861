import { TOAST_SET_SHOW } from '../actionTypes';

const initialState = {
  show: false,
  content: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOAST_SET_SHOW:
      return {
        ...state,
        show: action.show,
        content: action.content,
      };
    default:
      return state;
  }
};

export default reducer;
