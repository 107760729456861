import React from 'react';
import PropTypes from 'prop-types';
import {
  LineChart, Line, ResponsiveContainer, YAxis, Tooltip, XAxis, ReferenceLine,
} from 'recharts';
import useFetchStockPrice from '../hooks/useFetchStockPrice';
import palette from '../utils/palette';

const SingleStockPriceChart = ({ symbol, up, prevClose }) => {
  const prices = useFetchStockPrice(symbol);
  const data = prices.map((price) => ({
    name: price.label,
    value: price.average,
  }));
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={data}
      >
        <Tooltip formatter={(value) => ([`$${value}`])} />
        <XAxis dataKey="name" hide />
        <Line
          connectNulls
          isAnimationActive={false}
          type="monotone"
          dataKey="value"
          stroke={up ? palette.upGreenText : palette.downRedText}
          strokeWidth={2}
          dot={false}
        />
        <YAxis
          domain={[
            (dataMin) => Math.min(prevClose, dataMin),
            (dataMax) => Math.max(prevClose, dataMax)]}
          hide
        />
        {prices && prices.length > 0 && <ReferenceLine y={prevClose} stroke="#c4c4c4" strokeDasharray="3 3" />}
      </LineChart>
    </ResponsiveContainer>

  );
};

SingleStockPriceChart.propTypes = {
  symbol: PropTypes.string.isRequired,
  up: PropTypes.bool.isRequired,
  prevClose: PropTypes.number.isRequired,
};

export default SingleStockPriceChart;
