export const LOG_OUT = 'LOG_OUT';

export const UI_START_LOADING = 'UI_START_LOADING';
export const UI_STOP_LOADING = 'UI_STOP_LOADING';
export const UI_SET_SCREEN_SIZE = 'UI_SET_SCREEN_SIZE';

export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';

export const USER_SET_ORDERS = 'USER_SET_ORDERS';
export const USER_SET_PORTFOLIO = 'USER_SET_PORTFOLIO';
export const USER_SET_PROFILE = 'USER_SET_PROFILE';
export const USER_SET_ID = 'USER_SET_ID';
export const USER_SET_STOCKS_RETURN = 'USER_SET_STOCK_RETURN';

export const EDIT_ORDER_SHOW_MODAL = 'EDIT_ORDER_SHOW_MODAL';
export const EDIT_ORDER_CLOSE_MODAL = 'EDIT_ORDER_CLOSE_MODAL';

export const STOCK_FILTER_TOGGLE = 'STOCK_FILTER_TOGGLE';

export const TOAST_SET_SHOW = 'TOAST_SET_SHOW';
