import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import ReturnText from './ReturnText';

const styles = {
  ticker: {
    width: '10%',
  },
  companyName: {
    width: '30%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
};

const StocksNoLongerOwnedTable = ({ stocks }) => {
  const history = useHistory();
  const location = useLocation();
  const handleStockClicked = (symbol) => () => history.push(`stocks/${symbol}${location.search}`);
  return (
    <Table style={{ tableLayout: 'fixed', marginTop: 100 }} hover>
      <thead>
        <tr>
          <th style={styles.ticker}>Ticker</th>
          <th style={styles.companyName}>Company Name</th>
          <th>Total Return</th>
        </tr>
      </thead>
      <tbody>
        {stocks.map((stock) => (
          <tr
            key={stock.symbol}
            style={{ cursor: 'pointer' }}
            onClick={handleStockClicked(stock.symbol)}
          >
            <td>{stock.symbol}</td>
            <td style={styles.companyName}>{stock.profile.companyName}</td>
            <td>
              <ReturnText
                value={stock.user.totalReturn.equity}
                percentage={stock.user.totalReturn.percentage}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

StocksNoLongerOwnedTable.defaultProps = {
  stocks: [],
};

StocksNoLongerOwnedTable.propTypes = {
  stocks: PropTypes.array,
};

export default StocksNoLongerOwnedTable;
