import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HomePagePieChart from './HomePagePieChart';

const styles = {
  companyName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const HomePageListCell = ({
  list, keyName, valueName, animated, negative, isPercentage,
}) => (
  <Row style={{ minHeight: 200 }}>
    <Col xs={6} style={{ display: 'flex', alignItems: 'center' }}>
      <Table borderless size="sm" style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th style={{ width: '70%' }}>{keyName}</th>
            <th style={{ textAlign: 'end' }}>{valueName}</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item) => (
            <tr
              key={item.name}
            >
              <td style={styles.companyName}>{item.name}</td>
              <td style={{ textAlign: 'end' }}>{`${isPercentage ? '' : '$'}${item.value.toFixed(2)} ${isPercentage ? '%' : ''}`}</td>
            </tr>
          ))}

        </tbody>
      </Table>
    </Col>
    <Col xs={6}>
      <HomePagePieChart
        data={negative ? list.map((item) => ({ ...item, value: -item.value })) : list}
        animated={animated}
        isPercentage={isPercentage}
      />
    </Col>
  </Row>

);

HomePageListCell.defaultProps = {
  animated: false,
  negative: false,
  isPercentage: true,
};

HomePageListCell.propTypes = {
  list: PropTypes.array.isRequired,
  keyName: PropTypes.string.isRequired,
  valueName: PropTypes.string.isRequired,
  animated: PropTypes.bool,
  negative: PropTypes.bool,
  isPercentage: PropTypes.bool,
};

export default HomePageListCell;
