import { db } from '../apis/firebase';

/** @typedef {import('./typedef').StockQuote} Quote */

class Stock {
  constructor(symbol) {
    this.symbol = symbol;
    this.profile = {};
    this.quote = {};
    this.newsList = [];
    this.dividend = {};
    this.todayReturn = {};
    this.fiveDaysReturn = {};
    this.oneMonthReturn = {};
    this.threeMonthsReturn = {};
    this.stats = {};
    this.peers = [];
    this.isSP500 = false;

    this.stockDocSnapshot = null;
  }

  async getStockData() {
    if (!this.stockDocSnapshot) {
      const docRef = db.collection('stocks').doc(this.symbol);
      this.stockDocSnapshot = await docRef.get();
    }
    return this.stockDocSnapshot.data();
  }

  async getProfile() {
    const data = await this.getStockData();
    const { profile, isSP500 } = data;
    this.profile = profile;
    this.isSP500 = isSP500;
  }

  async getQuote() {
    const data = await this.getStockData();
    if (!data || !data.quote) {
      return;
    }
    const { quote } = data;
    this.quote = quote;
    const price = quote.latestPrice - quote.previousClose;
    const percentage = (price / quote.previousClose) * 100;
    this.todayReturn = { price, percentage };
  }

  async getDividend() {
    const data = await this.getStockData();
    const { dividend } = data;
    let annualDividend = 0;
    if (dividend.dividends.length > 0) {
      annualDividend = dividend.dividends
        .sort((a, b) => b.paymentDate - a.paymentDate)
        .slice(0, dividend.dividends[0].frequency === 'quarterly' ? 4 : 2)
        .reduce((total, d) => total + Number(d.amount), 0);
    }

    this.dividend = {
      lastUpdate: data.dividend.lastUpdate,
      history: dividend.dividends,
      yield: (annualDividend / this.quote.latestPrice) * 100,
    };
  }

  async getPeers() {
    const data = await this.getStockData();
    const { peers } = data;
    this.peers = peers;
  }

  async getQuoteAndDividendAndStats() {
    await this.getQuote();
    await this.getDividend();
    await this.getStats();
  }

  async getStats() {
    const data = await this.getStockData();
    const { stats } = data;
    this.stats = stats;
    this.fiveDaysReturn = {
      price: this.quote.latestPrice - this.quote.latestPrice / (1 + stats.day5ChangePercent),
      percentage: stats.day5ChangePercent * 100,
    };
    this.oneMonthReturn = {
      price: this.quote.latestPrice - this.quote.latestPrice / (1 + stats.month1ChangePercent),
      percentage: stats.month1ChangePercent * 100,
    };
    this.threeMonthsReturn = {
      price: this.quote.latestPrice - this.quote.latestPrice / (1 + stats.month3ChangePercent),
      percentage: stats.month3ChangePercent * 100,
    };
  }

  async getNews() {
    const data = await this.getStockData();
    const { news } = data;
    this.newsList = news.newsList
      .sort((a, b) => b.datetime - a.datetime)
      .filter((n) => n.lang === 'en')
      .map((n) => ({ ...n, symbol: this.symbol }));
  }

  toJson() {
    return {
      symbol: this.symbol,
      profile: this.profile,
      quote: this.quote,
      newsList: this.newsList,
      dividend: this.dividend,
      todayReturn: this.todayReturn,
      fiveDaysReturn: this.fiveDaysReturn,
      oneMonthReturn: this.oneMonthReturn,
      threeMonthsReturn: this.threeMonthsReturn,
      stats: this.stats,
      peers: this.peers,
      isSP500: this.isSP500,
    };
  }
}

export default Stock;
