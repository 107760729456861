import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  useHistory, useLocation,
} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { signIn, signUp } from '../store/actions/user';
import palette from '../utils/palette';

const styles = {
  container: {
    width: 400,
  },
  header: {
    fontSize: '2rem',
    fontWeight: 'bolder',
    marginBottom: 40,
    textAlign: 'center',
  },
  button: {
    marginTop: 30,
    paddingLeft: 20,
    paddingRight: 20,
  },
};

const AuthForm = () => {
  const {
    handleSubmit, register, errors, watch,
  } = useForm();

  const history = useHistory();

  const LOGIN_URL = '/login';
  const SIGNUP_URL = '/signup';
  const location = useLocation();
  const isLoginPage = location.pathname === LOGIN_URL;

  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const handleFormSubmited = async (values) => {
    try {
      const { email, password } = values;
      if (isLoginPage) {
        await dispatch(signIn(email, password));
      } else {
        const { firstName, lastName } = values;
        await dispatch(signUp({
          email, password, firstName, lastName,
        }));
      }
    } catch (err) {
      setErrorMsg(err.message);
    }
  };

  const handleAnchoredClicked = (url) => (e) => {
    e.preventDefault();
    setErrorMsg('');
    history.push(url);
  };
  let anchor;
  if (isLoginPage) {
    anchor = (
      <h6 style={{ textAlign: 'center' }}>
        Don&apos;t have an account?
        {' '}
        <a href="/" onClick={handleAnchoredClicked(SIGNUP_URL)} style={{ color: palette.primary }}>Sign up</a>
      </h6>
    );
  } else {
    anchor = (
      <h6 style={{ textAlign: 'center' }}>
        Already have an account?
        {' '}
        <a href="/" onClick={handleAnchoredClicked(LOGIN_URL)} style={{ color: palette.primary }}>Log in</a>
      </h6>
    );
  }
  return (
    <Form style={styles.container} onSubmit={handleSubmit(handleFormSubmited)}>
      <Form.Text style={styles.header}>{isLoginPage ? 'Welcome Back' : 'Join Now'}</Form.Text>
      {errorMsg && (
      <Alert variant="danger">
        {errorMsg}
      </Alert>
      )}
      <Form.Group>
        <Form.Label>Email address</Form.Label>
        <Form.Control
          ref={register({
            required: 'Required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'invalid email address',
            },
          })}
          isInvalid={errors.email}
          name="email"
        />
        <Form.Control.Feedback type="invalid">
          {errors.email && errors.email.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          name="password"
          ref={register({
            required: 'Required',
          })}
          isInvalid={errors.password}
        />
        <Form.Control.Feedback type="invalid">
          {errors.password && errors.password.message}
        </Form.Control.Feedback>
      </Form.Group>


      {!isLoginPage && (
        <>
          <Form.Group>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              name="confirmPassword"
              ref={register({
                required: 'Required',
                validate: (value) => value === watch('password') || "Passwords don't match.",
              })}
              isInvalid={errors.confirmPassword}
            />
            <Form.Control.Feedback type="invalid">
              {errors.confirmPassword && errors.confirmPassword.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              ref={register({
                required: 'Required',
              })}
              isInvalid={errors.firstName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstName && errors.firstName.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              ref={register({
                required: 'Required',
              })}
              isInvalid={errors.lastName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastName && errors.lastName.message}
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}
      <Button
        style={styles.button}
        block
        variant="primary"
        type="submit"
      >
        {isLoginPage ? 'Log In' : 'Sign Up'}
      </Button>
      <hr style={{ marginTop: '2rem', marginBottom: '2rem' }} />
      {anchor}
    </Form>
  );
};

export default AuthForm;
