import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuthenticated } from '../store/actions/user';

const useCheckAuthenticated = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => Boolean(state.user.isAuthenticated));
  useEffect(() => {
    dispatch(checkAuthenticated());
  }, [dispatch]);
  return isAuthenticated;
};

export default useCheckAuthenticated;
