import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getOthersPortfolio, getSelfPortfolio } from '../store/actions/user';
import useQuery from './useQuery';

const useFetchPortFolio = () => {
  const [loading, setLoading] = useState(true);
  const [errMsg, setErrMsg] = useState('');
  const { uid } = useQuery();
  const dispatch = useDispatch();
  useEffect(() => {
    const fetch = async () => {
      try {
        if (uid) {
          await dispatch(getOthersPortfolio(uid));
        } else {
          await dispatch(getSelfPortfolio());
        }
        setLoading(false);
      } catch (e) {
        setErrMsg(e.message);
        setLoading(false);
      }
    };
    fetch();
  }, [uid, dispatch]);
  return { loading, errMsg };
};

export default useFetchPortFolio;
