import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DropdownItem = ({ icon, children, onClick }) => (
  <Dropdown.Item onClick={onClick}>
    {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: 5 }} />}
    {' '}
    {children}
  </Dropdown.Item>
);

DropdownItem.defaultProps = {
  icon: null,
  onClick: null,
  children: '',
};

DropdownItem.propTypes = {
  icon: PropTypes.object,
  children: PropTypes.string,
  onClick: PropTypes.func,
};

export default DropdownItem;
