import React from 'react';
import PropTypes from 'prop-types';
import {
  useParams, useLocation,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import padding from '../utils/padding';
import palette from '../utils/palette';

const styles = {
  container: {
    marginRight: 10,
    border: '0px',
    borderRight: '0px',
    padding: padding.sidebarPaddingLeft,
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: 'transparent',
    color: 'slategray',
    cursor: 'pointer',
    fontWeight: 'normal',
    borderRadius: 0,
  },
  active: {
    color: palette.secondary,
    borderRight: `2px solid ${palette.secondary}`,
  },
};

const SidebarListItem = ({ children, url, icon }) => {
  const params = useParams();
  const loc = params[0];
  const location = useLocation();
  const active = url === loc;
  return (
    <ListGroupItem
      style={{ ...styles.container, ...active && styles.active }}
      action
      href={`#${url}${location.search}`}
    >
      {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: 15 }} />}
      {children}
    </ListGroupItem>
  );
};

SidebarListItem.defaultProps = {
  icon: null,
};

SidebarListItem.propTypes = {
  children: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.object,
};

export default SidebarListItem;
