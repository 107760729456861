import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import NewsBanner from './NewsBanner';
import SingleStockHistory from './SingleStockHistory';
import ReturnText from './ReturnText';
import SingleStockAboutSection from './SingleStockAboutSection';
import SingleStockCeoSection from './SingleStockCeoSection';
import SingleStockDividendLineChart from './SingleStockDividendLineChart';
import DashboardCell from './DashboardCell';
import SingleStockPeers from './SingleStockPeers';
import UnauthorizedPage from './UnauthorizedPage';
import SingleStockPriceChart from './SingleStockPriceChart';

const styles = {
  title: {
    fontSize: '2rem',
    fontWeight: 'bolder',
  },
  currentPrice: {
    fontSize: '1.8rem',
  },
  row: {
    marginBottom: 30,
  },
};

const cardStyles = {
  title: {
    fontWeight: 'bolder',
    fontSize: '1.4rem',
  },
};

const YourData = ({ title, content }) => (
  <h6 style={{ marginTop: 15, fontSize: '0.9rem' }}>
    {title}
    {' '}
    <span style={{ float: 'right' }}>{content}</span>
    {' '}
  </h6>
);

YourData.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.number]).isRequired,
};

const SingleStockPage = () => {
  const match = useRouteMatch();
  const { symbol } = match.params;
  const allOrders = useSelector((state) => state.user.orders);
  const stock = useSelector((state) => state.user.stocksDict[symbol]);
  const history = useHistory();
  const location = useLocation();
  if (!stock) {
    return <UnauthorizedPage message="You don&apos;t own this stock." />;
  }
  const orders = allOrders
    .filter((order) => order.stockSymbol === symbol)
    .sort((a, b) => b.date - a.date);
  const cashHeld = orders
    .filter((order) => order.action === 'sell')
    .reduce((total, order) => total + order.price * order.shares, 0);
  let dividendCell;
  if (!stock.dividend.history || stock.dividend.history.length === 0) {
    dividendCell = <h6 style={{ fontSize: '0.9rem', margin: 0 }}>There is no dividend history for this stock</h6>;
  } else {
    dividendCell = <SingleStockDividendLineChart dividends={stock.dividend.history} />;
  }
  const handleStocksClicked = () => history.push(`/stocks${location.search}`);
  return stock ? (
    <Container fluid style={{ padding: 0 }}>
      <Helmet>
        <title>{`Stocks - ${symbol}`}</title>
      </Helmet>
      <Row style={styles.row}>
        <Col xs={12}>
          <Breadcrumb>
            <Breadcrumb.Item onClick={handleStocksClicked}>
              Stocks
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{symbol}</Breadcrumb.Item>
          </Breadcrumb>
          <h5 style={styles.title}>{stock.profile.companyName}</h5>
          <h5 style={styles.currentPrice}>{`$${stock.quote.latestPrice}`}</h5>
        </Col>
      </Row>
      <Row>
        <Col xs={8}>

          <Row xs={1} style={styles.row}>
            <Col>
              <SingleStockPriceChart
                symbol={symbol}
                up={stock.todayReturn.percentage >= 0}
                prevClose={stock.quote.previousClose}
              />
            </Col>
          </Row>
          <Row style={styles.row}>
            <Col xs={6}>
              <DashboardCell title="Equity">
                <>
                  {stock.user.shares > 0 && <h5 style={cardStyles.title}>{`$${stock.user.equity.toFixed(2)}`}</h5>}
                  {stock.user.shares > 0 && <YourData title="Cost" content={`$${stock.user.cost.toFixed(2)}`} />}
                  {stock.user.shares > 0 && (
                  <YourData
                    title="Today&apos;s Return"
                    content={(
                      <>
                        <ReturnText
                          value={stock.user.todayReturn.equity}
                          percentage={stock.user.todayReturn.percentage}
                          colored={false}
                        />
                      </>
                )}
                  />
                  )}
                  <YourData
                    title="Total Return"
                    content={(
                      <>
                        <ReturnText
                          value={stock.user.totalReturn.equity}
                          percentage={stock.user.totalReturn.percentage}
                          colored={false}
                        />
                      </>
                )}
                  />
                </>
              </DashboardCell>
            </Col>
            <Col xs={6}>

              <DashboardCell title="Average Cost">
                <>
                  {stock.user.shares > 0 && <h5 style={cardStyles.title}>{`$${stock.user.averagePrice.toFixed(2)}`}</h5>}
                  {stock.user.shares > 0 && <YourData title="Shares" content={stock.user.shares} />}
                  {stock.user.shares > 0 && <YourData title="Diversity" content={`${stock.user.diversity.toFixed(2)}%`} />}
                  <YourData title="Cash held" content={`$${cashHeld.toFixed(2)}`} />
                </>
              </DashboardCell>
            </Col>
          </Row>
          <Row style={styles.row}>
            <Col xs={12}>
              <DashboardCell title="About">
                <SingleStockAboutSection stock={stock} />
              </DashboardCell>
            </Col>
          </Row>
          {stock.profile.CEOCompensation && (
            <Row style={styles.row}>
              <Col xs={12}>
                <DashboardCell title={`CEO Compensation - ${stock.profile.CEOCompensation.name}`}>
                  <SingleStockCeoSection CEOCompensation={stock.profile.CEOCompensation} />
                </DashboardCell>
              </Col>
            </Row>
          )}
          <Row style={styles.row}>
            <Col>
              <DashboardCell title="Dividends">
                {dividendCell}
              </DashboardCell>
            </Col>
          </Row>

          <Row style={styles.row}>
            <Col xs={12}>
              <DashboardCell title="Order history">
                <SingleStockHistory orders={orders} />
              </DashboardCell>
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          <Row style={styles.row}>
            <Col xs={12}>
              <DashboardCell title="Peers">
                <SingleStockPeers peers={stock.peers} />
              </DashboardCell>
            </Col>
          </Row>
          <Row style={styles.row}>
            <Col xs={12}>
              <DashboardCell title="News">
                <>
                  {stock.newsList.map((news) => <NewsBanner key={news.url} news={news} />)}
                </>
              </DashboardCell>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>

  ) : (
    <h5>You don&apos;t own this stock.</h5>
  );
};

export default SingleStockPage;
