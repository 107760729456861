import { db, functions } from '../apis/firebase';
import Order from './order';
import UserStock from './userStock';
import { isLongerThanDays } from '../utils/time';

/** @typedef {import('./typedef').Order} Order */
/** @typedef {import('./typedef').Stock} Stock */
/** @typedef {import('./typedef').UserStock} UserStock */
/** @typedef {import('./typedef').UserEquity} UserEquity */


const calculateShareAndAveragePriceWithOrders = (orders) => {
  const actionValue = { buy: 1, sell: -1 };
  orders.sort((a, b) => a.date - b.date);
  return orders.reduce((state, order) => {
    const shares = state.shares + order.shares * actionValue[order.action];
    const averagePrice = order.action === 'sell' ? state.averagePrice
      : (state.averagePrice * state.shares + order.price * order.shares) / shares;
    return { shares, averagePrice };
  }, { averagePrice: 0, shares: 0 });
};

class User {
  constructor(uid) {
    this.uid = uid;
    this.profile = {
      firstName: '',
      lastName: '',
    };
    this.settings = {
      public: false,
    };

    this.userRef = db.collection('users').doc(uid);
  }

  async createWithName(firstName, lastName) {
    await this.userRef.set({ firstName, lastName });
  }

  async get() {
    const docSnapshot = await this.userRef.get();
    if (!docSnapshot.exists) {
      throw new Error('Invalid User ID');
    }
    const data = docSnapshot.data();
    this.profile = data.profile;
    this.settings = data.settings;
  }

  async updateStock(order, deletion = false) {
    const userStockRef = this.userRef.collection('stocks').doc(order.stockSymbol);
    const docSnapshot = await userStockRef.get();
    if (!docSnapshot.exists) {
      if (deletion) {
        throw new Error('Deleting not existing stock from user.');
      }
      if (order.action === 'sell') {
        throw new Error('Selling not existing stock from user.');
      }
      await userStockRef.set({
        shares: order.shares,
        averagePrice: order.price,
      });
    } else {
      const querySnapshot = await this.userRef
        .collection('stocks').doc(docSnapshot.id)
        .collection('orders').get();
      if (querySnapshot.size === 0) {
        await userStockRef.delete();
        return;
      }
      const orders = querySnapshot.docs.map((doc) => doc.data());
      const { averagePrice, shares } = calculateShareAndAveragePriceWithOrders(orders);
      await userStockRef.update({
        shares,
        averagePrice,
      });
    }


    const stockRef = db.collection('stocks').doc(order.stockSymbol);
    const stockDocSnapshot = await stockRef.get();
    const stockData = stockDocSnapshot.data();
    if (!stockData.latestUpdate || isLongerThanDays(stockData.latestUpdate.toDate(), 1)) {
      const updateSingleStockDetails = functions.httpsCallable('updateSingleStockDetails');
      await updateSingleStockDetails({ symbol: order.stockSymbol });
    }
  }

  async updateOrder(oid, data) {
    const docSnapshot = await this.userRef
      .collection('stocks').doc(data.stockSymbol)
      .collection('orders').doc(oid)
      .get();
    if (!docSnapshot.exists) {
      throw Object({
        message: 'Order not exists.',
      });
    }
    const oldOrder = new Order(docSnapshot.data(), docSnapshot.id);
    const newData = {
      date: new Date(data.date).getTime(),
      action: data.action,
      stockSymbol: data.stockSymbol,
      price: data.price,
      shares: data.shares,
      note: data.note || '',
    };
    await docSnapshot.ref.update(newData);
    const newOrder = new Order(newData, oid);
    await this.updateStock(oldOrder, true);
    await this.updateStock(newOrder, false);
    return newOrder.toJson();
  }

  async deleteOrder(oid, stockSymbol) {
    const docSnapshot = await this.userRef
      .collection('stocks').doc(stockSymbol)
      .collection('orders').doc(oid)
      .get();
    if (!docSnapshot.exists) {
      throw Object({
        message: 'Order not exists.',
      });
    }
    const order = new Order(docSnapshot.data(), docSnapshot.id);
    await docSnapshot.ref.delete();
    // Should update stock after order is deleted.
    await this.updateStock(order, true);
  }

  async createOrder({
    date, action, price, stockSymbol, shares,
  }) {
    const stockDocSnapshot = await db.collection('stocks').doc(stockSymbol).get();
    if (!stockDocSnapshot.exists) {
      throw new Error('Stock symbol not exists.');
    }

    const newData = {
      date: new Date(date).getTime(),
      action,
      stockSymbol,
      price: parseFloat(price),
      shares: parseInt(shares, 10),
    };
    const docData = await this.userRef
      .collection('stocks').doc(stockSymbol)
      .collection('orders').add(newData);
    const newOrder = new Order(newData, docData.id);
    await this.updateStock(newOrder, false);

    return newOrder.toJson();
  }

  toJson() {
    return {
      uid: this.uid,
      profile: this.profile,
      settings: this.settings,
    };
  }

  async getStocks() {
    const querySnapshot = await this.userRef.collection('stocks')
      .get();
    const stocks = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return new UserStock(doc.id, this.uid, data.averagePrice, data.shares);
    });
    await Promise.all([
      ...stocks.map((st) => st.getProfile()),
      ...stocks.map((st) => st.getQuoteAndDividendAndStats()),
      ...stocks.map((st) => st.getNews()),
      ...stocks.map((st) => st.getPeers()),
      ...stocks.map((st) => st.getOrders()),
    ]);


    const totalEquity = stocks
      .reduce((total, stock) => total + stock.user.shares * stock.quote.latestPrice, 0);
    stocks.forEach((stock) => {
      stock.calculateDiversityAndReturn(totalEquity);
    });
    return stocks.map((stock) => stock.toJson());
  }

  static getSectors(stocks) {
    const sectors = {};
    stocks.forEach((stock) => {
      if (stock.user.shares <= 0) {
        return;
      }
      const { sector } = stock.profile;
      if (!sectors[sector]) {
        sectors[sector] = {
          stocks: [],
          cost: 0,
          equity: 0,
        };
      }
      sectors[sector].stocks.push(stock.symbol);
      sectors[sector].cost += stock.user.averagePrice * stock.user.shares;
      sectors[sector].equity += stock.quote.latestPrice * stock.user.shares;
    });
    const totalEquityFromSectors = Object.keys(sectors)
      .reduce((total, name) => total + sectors[name].equity, 0);
    Object.keys(sectors).forEach((name) => {
      sectors[name].diversity = (sectors[name].equity / totalEquityFromSectors) * 100;
    });
    return Object.keys(sectors).map((sectorName) => ({ ...sectors[sectorName], name: sectorName }));
  }

  /**
   *
   * @param {Array<UserStock>} stocks
   * @param {Array<Order>} orders
   *
   * @return {UserEquity}
   */
  static getEquity(stocks, orders) {
    const stockEquity = stocks.reduce(
      (total, stock) => total + stock.user.shares * stock.quote.latestPrice, 0,
    );
    const sellOrders = orders.filter((order) => order.action === 'sell');
    const cashEquity = sellOrders.reduce((total, order) => total + order.price * order.shares, 0);

    const getReturnByDateRange = (returnName) => {
      const equityReturn = stocks
        .reduce((total, stock) => total + stock.user[returnName].equity, 0);
      const equityCost = stocks
        .reduce((total, stock) => total + stock.user[returnName].meta.cost, 0);
      return {
        equity: equityReturn,
        percentage: (equityReturn / equityCost) * 100 || 0,
      };
    };

    return {
      stocks: stockEquity,
      cash: cashEquity,
      todayReturn: getReturnByDateRange('todayReturn'),
      fiveDaysReturn: getReturnByDateRange('fiveDaysReturn'),
      oneMonthReturn: getReturnByDateRange('oneMonthReturn'),
      threeMonthsReturn: getReturnByDateRange('threeMonthsReturn'),
      totalReturn: getReturnByDateRange('totalReturn'),
    };
  }

  /**
   *
   * @param {Array<UserStock>} stocks
   */
  static getTopBottomPerforming(stocks) {
    const sortedByPercentage = stocks
      .filter((stock) => stock.user.shares > 0)
      .sort((a, b) => b.user.totalReturn.percentage - a.user.totalReturn.percentage)
      .map((stock) => stock.symbol);
    const sortedByEquity = stocks
      .sort((a, b) => b.user.totalReturn.equity - a.user.totalReturn.equity)
      .map((stock) => stock.symbol);
    return {
      top: {
        percentage: sortedByPercentage.slice(0, 5),
        equity: sortedByEquity.slice(0, 5),
      },
      bottom: {
        percentage: sortedByPercentage.slice(Math.max(sortedByPercentage.length - 5, 0)).reverse(),
        equity: sortedByEquity.slice(Math.max(sortedByEquity.length - 5, 0)).reverse(),
      },
    };
  }

  async getPortfolio() {
    const stocks = await this.getStocks();
    const orders = stocks.map((stock) => stock.user.orders)
      .flat()
      .sort((a, b) => b.date - a.date);
    const equity = User.getEquity(stocks, orders);
    const sectors = await User.getSectors(stocks);
    const topBottomPerforming = User.getTopBottomPerforming(stocks);
    return {
      orders, stocks, equity, sectors, topBottomPerforming,
    };
  }

  async checkPublic() {
    const userDocSnapshot = await this.userRef.get();
    if (!userDocSnapshot.exists) {
      throw new Error('User doesn\'t exist.');
    }
    const userData = userDocSnapshot.data();
    return userData.settings && userData.settings.public;
  }

  updateSettings(payload) {
    const settings = {
      public: payload.public,
    };
    return this.userRef.update({ settings });
  }
}

export default User;
