import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser, faCog, faShare, faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { signOut } from '../store/actions/user';
import { toggleShowToast } from '../store/actions/toast';
import DefaultButton from './DefaultButton';
import DropdownItem from './DropdownItem';
import padding from '../utils/padding';
import useNavbarTitle from './useNavbarTitle';
import usePageYOffet from '../hooks/usePageYOffet';

const styles = {
  container: {
    marginLeft: 260,
    height: 70,
    paddingLeft: padding.mainApp,
    paddingRight: padding.mainApp,
  },
};

const NavBar = () => {
  const pageYOffset = usePageYOffet();
  const dispatch = useDispatch();
  const handleLogOutClicked = () => {
    dispatch(signOut());
  };

  const history = useHistory();
  const handleSettingsClicked = () => {
    history.push('/settings');
  };
  const handleLoginClicked = () => {
    history.push('/login');
  };
  const uid = useSelector((state) => state.user.id);

  const handleShareClicked = () => {
    navigator.clipboard.writeText(`http://finance.andychien.me/#/dashboard?uid=${uid}`);
    dispatch(toggleShowToast(true, 'Link copied to clipboard!'));
  };

  const title = useNavbarTitle();

  const isAuthenticated = useSelector((state) => Boolean(state.user.isAuthenticated));
  return (
    <Navbar
      bg="background"
      style={{ ...styles.container, ...pageYOffset > 20 && { boxShadow: '0 2px 5px rgba(0,0,0,.2)' } }}
      fixed="top"
    >
      <Navbar.Brand style={{ fontWeight: 'bolder' }}>{title}</Navbar.Brand>
      <Navbar.Collapse className="justify-content-end">
        {isAuthenticated ? (
          <>
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-cancel"
                size="sm"
              >
                <FontAwesomeIcon icon={faUser} />
              </Dropdown.Toggle>

              <Dropdown.Menu alignRight>
                <DropdownItem
                  icon={faCog}
                  onClick={handleSettingsClicked}
                >
                  Settings
                </DropdownItem>
                <DropdownItem
                  icon={faShare}
                  onClick={handleShareClicked}
                >
                  Share
                </DropdownItem>
                <DropdownItem
                  icon={faSignOutAlt}
                  onClick={handleLogOutClicked}
                >
                  Log out
                </DropdownItem>
              </Dropdown.Menu>
            </Dropdown>
          </>
        ) : (
          <><DefaultButton variant="forth" size="sm" onClick={handleLoginClicked}>Log in</DefaultButton></>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
