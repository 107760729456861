import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import useFetchQuote from '../hooks/useFetchQuote';
import ReturnText from './ReturnText';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 10,
  },
};

const SingleStockPeersItem = ({ symbol }) => {
  const { loading, todayReturn } = useFetchQuote(symbol);
  let rightContent;
  if (loading) {
    rightContent = <Spinner size="sm" animation="grow" variant="secondary" />;
  } else if (todayReturn && todayReturn.price && todayReturn.percentage) {
    rightContent = (
      <ReturnText
        value={todayReturn.price}
        percentage={todayReturn.percentage}
      />
    );
  } else {
    rightContent = <h6>--</h6>;
  }
  return (
    <div style={styles.container}>
      <h6>{symbol}</h6>
      {rightContent}
    </div>
  );
};

SingleStockPeersItem.propTypes = {
  symbol: PropTypes.string.isRequired,
};

export default SingleStockPeersItem;
