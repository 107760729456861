import { useState, useLayoutEffect } from 'react';


const useLayoutWidth = (ref) => {
  const [width, setWidth] = useState(0);
  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, [ref]);
  return width;
};

export default useLayoutWidth;
