import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SingleStockAboutCell from './SingleStockAboutCell';

const SingleStockAboutSection = ({ stock }) => (
  <>
    <Row style={{ marginBottom: 20 }}>
      <Col xs={12}>
        <p style={{ fontSize: '0.9rem' }}>{stock.profile.description}</p>
      </Col>
    </Row>
    <Row xs={4}>
      <Col>
        <SingleStockAboutCell title="Market Cap" content={numeral(stock.stats.marketcap).format('0.0a').toUpperCase()} />
      </Col>
      <Col>
        <SingleStockAboutCell title="Dividend Yield" content={stock.dividend.yield.toFixed(2)} />
      </Col>
      <Col>
        <SingleStockAboutCell title="Sector" content={stock.profile.sector} />
      </Col>
      <Col>
        <SingleStockAboutCell title="Location" content={`${stock.profile.city}, ${stock.profile.state}`} />
      </Col>
      <Col>
        <SingleStockAboutCell title="52 Week High" content={stock.quote.week52High} />
      </Col>
      <Col>
        <SingleStockAboutCell title="52 Week Low" content={stock.quote.week52Low} />
      </Col>
      <Col>
        <SingleStockAboutCell title="P/E Ratio" content={stock.stats.peRatio} />
      </Col>
      <Col>
        <SingleStockAboutCell title="EPS" content={stock.stats.ttmEPS.toFixed(2)} />
      </Col>
    </Row>
  </>
);

SingleStockAboutSection.propTypes = {
  stock: PropTypes.object.isRequired,
};

export default SingleStockAboutSection;
