import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const styles = {
  container: {
    position: 'relative',
  },
  spinnerDiv: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const DefaultButton = ({
  children, disabled, loading, onClick, size, style, type, variant, block,
}) => (
  <Button
    disabled={loading || disabled}
    type={type}
    variant={variant}
    onClick={onClick}
    onMouseDown={(e) => e.preventDefault()}
    size={size}
    style={{ ...style, ...styles.container }}
    block={block}
  >
    {children}
    {loading && (
    <div style={styles.spinnerDiv}>
      <Spinner animation="border" size={size} />
    </div>
    )}
  </Button>
);

DefaultButton.defaultProps = {
  disabled: false,
  loading: false,
  onClick: null,
  size: null,
  style: {},
  type: 'button',
  variant: 'primary',
  block: false,
};

DefaultButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['lg', 'sm', null]),
  style: PropTypes.object,
  type: PropTypes.string,
  variant: PropTypes.string,
  block: PropTypes.bool,
};

export default DefaultButton;
