import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DashboardCell from './DashboardCell';
import DateRangeReturnChart from './DateRangeReturnChart';
import ReturnText from './ReturnText';

const styles = {
  row: {
    marginBottom: 20,
  },
};

const ReturnPage = () => {
  const stocksReturn = useSelector((state) => state.user.stocksReturn);
  const equity = useSelector((state) => state.user.equity);
  return (
    <>
      <Helmet>
        <title>Return</title>
      </Helmet>
      <Container fluid>
        <Row style={styles.row} xs={5}>
          <Col>
            <DashboardCell title="Today's return">
              <ReturnText
                value={equity.todayReturn.equity}
                percentage={equity.todayReturn.percentage}
              />
            </DashboardCell>
          </Col>
          <Col>
            <DashboardCell title="5 days' return">
              <ReturnText
                value={equity.fiveDaysReturn.equity}
                percentage={equity.fiveDaysReturn.percentage}
              />
            </DashboardCell>
          </Col>
          <Col>
            <DashboardCell title="1 Month's return">
              <ReturnText
                value={equity.oneMonthReturn.equity}
                percentage={equity.oneMonthReturn.percentage}
              />
            </DashboardCell>
          </Col>
          <Col>
            <DashboardCell title="3 Month's return">
              <ReturnText
                value={equity.threeMonthsReturn.equity}
                percentage={equity.threeMonthsReturn.percentage}
              />
            </DashboardCell>
          </Col>
          <Col>
            <DashboardCell title="Total return">
              <ReturnText
                value={equity.totalReturn.equity}
                percentage={equity.totalReturn.percentage}
              />
            </DashboardCell>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <DashboardCell title="Up stocks">
              <DateRangeReturnChart stocksReturn={stocksReturn} side="up" />
            </DashboardCell>
          </Col>
          <Col xs={6}>
            <DashboardCell title="Down stocks">
              <DateRangeReturnChart stocksReturn={stocksReturn} side="down" />
            </DashboardCell>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ReturnPage;
