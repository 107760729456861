import React, { useRef, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Line, ResponsiveContainer,
} from 'recharts';
import palette from '../utils/palette';

const SingleStockDividendLineChart = ({ dividends }) => {
  const [height, setHeight] = useState(0);
  const divRef = useRef();
  const data = dividends
    .map((dividend) => ({
      name: dividend.declaredDate,
      value: Number(dividend.amount),
    })).reverse();
  useLayoutEffect(() => {
    setHeight(divRef.current.offsetWidth * 0.5);
  }, []);
  return (
    <div ref={divRef}>
      <ResponsiveContainer width="100%" height={height}>
        <LineChart
          data={data}
          margin={{
            top: 5, right: 30, bottom: 40,
          }}
        >
          <XAxis
            dataKey="name"
            tick={({
              x, y, payload,
            }) => (
              <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                  {moment(new Date(payload.value)).format('YYYY-MM')}
                </text>
              </g>
            )}
          />
          <YAxis
            tick={({
              x, y, payload,
            }) => (
              <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={6} textAnchor="end" fill="#666">
                  {`$${payload.value}`}
                </text>
              </g>
            )}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip formatter={(value) => ([`$${value}`, 'Amount paid'])} />
          <Line
            type="monotone"
            dataKey="value"
            stroke={palette.secondary}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

SingleStockDividendLineChart.propTypes = {
  dividends: PropTypes.array.isRequired,
};

export default SingleStockDividendLineChart;
