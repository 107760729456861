import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import { showModal } from '../store/actions/editOrder';
import palette from '../utils/palette';

const HomePageRecentOrders = () => {
  const orders = useSelector((state) => state.user.orders).slice(0, 10);
  const dispatch = useDispatch();
  const handleOrderClicked = (order) => () => dispatch(showModal(order));
  return (
    <Table borderless hover size="sm">
      <thead>
        <tr>
          <th>Symbol</th>
          <th>Amount</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {orders.map((order) => (
          <tr
            key={order.oid}
            style={{ cursor: 'pointer', color: order.action === 'buy' ? palette.upGreenText : palette.downRedText }}
            onClick={handleOrderClicked(order)}
          >
            <td>{order.stockSymbol}</td>
            <td>{`$${(order.shares * order.price).toFixed(2)}`}</td>
            <td>{order.action}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default HomePageRecentOrders;
