import { STOCK_FILTER_TOGGLE } from '../actionTypes';

const initialState = {
  sector: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STOCK_FILTER_TOGGLE:
      return {
        ...state,
        sector: {
          ...state.sector,
          [action.tag]: !state.sector[action.tag],
        },
      };
    default:
      return state;
  }
};

export default reducer;
