import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import NewsCard from './NewsCard';

const NewsPage = () => {
  const stocks = useSelector((state) => state.user.stocks);
  let allNewsList = stocks
    .map((stock) => stock.newsList)
    .flat()
    .sort((a, b) => b.datetime - a.datetime)
    .slice(0, 20);
  allNewsList = Array.from(new Set(allNewsList.map((news) => news.headline)))
    .map((headline) => allNewsList.find((news) => news.headline === headline));
  return (
    <Container fluid>
      <Helmet>
        <title>News</title>
      </Helmet>
      <Row>
        {allNewsList.map((news) => (
          <Col xs={3} key={news.url}>
            <NewsCard news={news} />
            <hr style={{ marginTop: '2rem', marginBottom: '2rem' }} />
          </Col>
        ))}
      </Row>
    </Container>

  );
};

export default NewsPage;
