import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import FlexHeightImage from './FlexHeightImage';
import palette from '../utils/palette';

const styles = {
  container: {
    border: 'none',
    cursor: 'pointer',
  },
  title: {
    lineHeight: '20px',
    height: 80,
    overflow: 'hidden',
    fontSize: '1rem',
    marginBottom: 0,
  },
  subtitle: {
    fontSize: '0.8rem',
    color: 'gray',
    fontWeight: 'normal',
    marginBottom: 20,
    display: 'flex',
  },
  source: {
    width: '70%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  datetime: {
    width: '30%',
    textAlign: 'end',
  },
  body: {
    padding: '1rem',
  },
  related: {
    marginTop: 10,
    fontSize: '0.8rem',
    fontWeight: 'bolder',
  },
};

const NewsCard = ({ news }) => {
  const stock = useSelector((state) => state.user.stocksDict[news.symbol]);
  const growth = stock.todayReturn.percentage;
  const growthColor = growth > 0 ? palette.upGreenText : palette.downRedText;
  const handleCardClick = () => {
    window.open(news.url, '_blank');
  };
  return (
    <Card style={styles.container} onClick={handleCardClick}>
      <Card.Body style={styles.body}>
        <Card.Subtitle style={styles.subtitle}>
          <span style={styles.source}>{news.source}</span>
          <span style={styles.datetime}>{moment(news.datetime).fromNow(true)}</span>
        </Card.Subtitle>
        <Card.Title style={styles.title}>{news.headline}</Card.Title>
        <Card.Subtitle style={{ ...styles.related, color: growthColor }}>
          {`${news.symbol} ${(growth < 0 ? '' : '+')}${growth.toFixed(2)}%`}
        </Card.Subtitle>
      </Card.Body>
      <FlexHeightImage image={news.image} height="70%" />
    </Card>
  );
};

NewsCard.propTypes = {
  news: PropTypes.object.isRequired,
};

export default NewsCard;
