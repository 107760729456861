import {
  UI_START_LOADING, UI_STOP_LOADING, UI_SET_SCREEN_SIZE,
} from '../actionTypes';

const initialState = {
  isLoading: {},
  screen: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UI_START_LOADING:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.loadingType]: true,
        },
      };
    case UI_STOP_LOADING:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.loadingType]: false,
        },
      };
    case UI_SET_SCREEN_SIZE:
      return {
        ...state,
        screen: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
