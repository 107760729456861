import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { createOrder, updateOrder, deleteOrder } from '../store/actions/user';
import { closeModal } from '../store/actions/editOrder';
import { USER_CREATE_ORDER } from '../store/loadingTypes';
import DefaultButton from './DefaultButton';
import useOrderModalViewOnly from '../hooks/useOrderModalViewOnly';
import palette from '../utils/palette';

const styles = {
  viewOnlyText: {
    color: palette.primary,
    fontSize: '1rem',
  },
};

const EditOrderModal = () => {
  const {
    handleSubmit, register, errors, setValue,
  } = useForm({
    defaultValues: {
      date: '',
      stockSymbol: '',
      price: '0',
      shares: '0',
      action: 'buy',
      note: '',
    },
  });
  const show = useSelector((state) => state.editOrder.showModal);
  const order = useSelector((state) => state.editOrder.order);
  const isLoading = useSelector((state) => Boolean(state.ui.isLoading[USER_CREATE_ORDER]));

  const [errorMsg, setErrorMsg] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (order) {
      setValue('date', moment(order.date).local().format('L'));
      setValue('stockSymbol', order.stockSymbol);
      setValue('shares', order.shares);
      setValue('price', order.price);
      setValue('action', order.action);
      setValue('note', order.note);
    }
  }, [order, setValue]);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleFormSubmit = async (values) => {
    const {
      date, stockSymbol, shares, price, action, note,
    } = values;
    const data = {
      date,
      stockSymbol,
      shares: parseInt(shares, 10),
      price: parseFloat(price),
      action,
      note,
    };
    try {
      if (order) {
        await dispatch(updateOrder(data, order.oid));
      } else {
        await dispatch(createOrder(data));
      }
      handleClose();
    } catch (err) {
      setErrorMsg(err.message);
    }
  };

  const handleDeleteClicked = async () => {
    try {
      await dispatch(deleteOrder(order.oid, order.stockSymbol));
      handleClose();
    } catch (err) {
      setErrorMsg(err.message);
    }
  };

  const isViewOnly = useOrderModalViewOnly(show, order);
  let title;
  if (isViewOnly) {
    title = 'View order';
  } else if (order) {
    title = 'Edit order';
  } else {
    title = 'Create new order';
  }
  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!!errorMsg && (
          <Alert variant="danger">
            {errorMsg}
          </Alert>
          )}
          <Form.Group>
            <Form.Label>Date</Form.Label>
            {!isViewOnly && (
            <Form.Control
              type="text"
              ref={register({
                required: 'Required',
                validate: (value) => moment(new Date(value)).isValid() || 'Date invalid.',
              })}
              name="date"
              isInvalid={errors.date}
            />
            )}
            {isViewOnly && <h6 style={styles.viewOnlyText}>{moment(order.date).local().format('L')}</h6>}
            <Form.Control.Feedback type="invalid">
              {errors.date && errors.date.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Ticker symbol</Form.Label>
            {!isViewOnly && (
            <Form.Control
              type="text"
              ref={register({
                required: 'Required',
              })}
              name="stockSymbol"
              isInvalid={errors.stockSymbol}
            />
            )}
            {isViewOnly && <h6 style={styles.viewOnlyText}>{order.stockSymbol}</h6>}
            <Form.Control.Feedback type="invalid">
              {errors.stockSymbol && errors.stockSymbol.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Price</Form.Label>
            {!isViewOnly && (
            <Form.Control
              ref={register({
                required: 'Required',
                validate: (value) => value > 0 || 'Only positive numbers are accepted',
              })}
              isInvalid={errors.price}
              name="price"
            />
            )}
            {isViewOnly && <h6 style={styles.viewOnlyText}>{`$${order.price}`}</h6>}
            <Form.Control.Feedback type="invalid">
              {errors.price && errors.price.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Number of shares</Form.Label>
            {!isViewOnly && (
            <Form.Control
              type="number"
              ref={register({
                required: 'Required',
                pattern: {
                  value: /^[1-9]\d*$/,
                  message: 'Only positive integers are accepted.',
                },
              })}
              name="shares"
              isInvalid={errors.shares}
            />
            )}
            {isViewOnly && <h6 style={styles.viewOnlyText}>{order.shares}</h6>}
            <Form.Control.Feedback type="invalid">
              {errors.shares && errors.shares.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Type of action</Form.Label>
            {!isViewOnly && (
            <Form.Control
              as="select"
              ref={register({
                required: 'Required',
              })}
              name="action"
            >
              <option value="buy">Buy</option>
              <option value="sell">Sell</option>
            </Form.Control>
            )}
            {isViewOnly && <h6 style={styles.viewOnlyText}>{order.action}</h6>}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Note
              {!isViewOnly && ' (Optional)'}
            </Form.Label>
            {!isViewOnly && (
            <Form.Control
              as="textarea"
              ref={register()}
              name="note"
              rows={5}
            />
            )}
            {isViewOnly && <p style={styles.viewOnlyText}>{order.note}</p>}
          </Form.Group>
        </Modal.Body>
        {!isViewOnly && (
        <Modal.Footer>
          {order && (
          <DefaultButton variant="danger" onClick={handleDeleteClicked} disabled={isLoading}>
            Delete
          </DefaultButton>
          )}
          <DefaultButton variant="cancel" onClick={handleClose} disabled={isLoading}>
            Close
          </DefaultButton>
          <DefaultButton variant="secondary" type="submit" loading={isLoading}>
            {order ? 'Update' : 'Create'}
          </DefaultButton>
        </Modal.Footer>
        )}
      </Form>
    </Modal>
  );
};

export default EditOrderModal;
