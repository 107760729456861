import { useState, useEffect } from 'react';

const usePageYOffet = () => {
  const [offset, setOffset] = useState(window.pageYOffset);
  const handleScroll = () => {
    setOffset(window.pageYOffset);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return window.removeEventListener('scroll', handleScroll);
  }, []);
  return offset;
};

export default usePageYOffet;
