import React from 'react';
import AuthForm from './AuthForm';

const styles = {
  container: {
    height: '100vh',
    display: 'flex',
  },
  left: {
    width: '45%',
    minWidth: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  right: {
    width: '55%',
    backgroundColor: '#e3e6e8',
  },
};

const AuthPage = () => (
  <div style={styles.container}>
    <div style={styles.left}>
      <AuthForm />
    </div>
    <div style={styles.right} />
  </div>
);

export default AuthPage;
