import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HomePageListCell from './HomePageListCell';
import DateRangeButton from './DateRangeButton';

const DateRangeReturnChart = ({ stocksReturn, side }) => {
  const [dateRange, setDateRange] = useState('todayReturn');
  const handleButtonClicked = (value) => () => {
    setDateRange(value);
  };
  const data = stocksReturn[dateRange][side].map((stock) => ({
    name: stock.profile.companyName,
    value: stock.user[dateRange].equity,
  }));
  return (
    <>
      <div>
        <DateRangeButton onClick={handleButtonClicked('todayReturn')} selected={dateRange === 'todayReturn'}>Today</DateRangeButton>
        <DateRangeButton onClick={handleButtonClicked('fiveDaysReturn')} selected={dateRange === 'fiveDaysReturn'}>5D</DateRangeButton>
        <DateRangeButton onClick={handleButtonClicked('oneMonthReturn')} selected={dateRange === 'oneMonthReturn'}>1M</DateRangeButton>
        <DateRangeButton onClick={handleButtonClicked('threeMonthsReturn')} selected={dateRange === 'threeMonthsReturn'}>3M</DateRangeButton>
        <DateRangeButton onClick={handleButtonClicked('totalReturn')} selected={dateRange === 'totalReturn'}>Total</DateRangeButton>
      </div>
      <div>
        <HomePageListCell
          list={data}
          keyName="Stock"
          valueName="Amount"
          negative={side === 'down'}
          isPercentage={false}
        />
      </div>
    </>
  );
};

DateRangeReturnChart.propTypes = {
  stocksReturn: PropTypes.object.isRequired,
  side: PropTypes.oneOf(['up', 'down']).isRequired,
};

export default DateRangeReturnChart;
