import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const useNavbarTitle = () => {
  const [title, setTitle] = useState('');
  const params = useParams();
  useEffect(() => {
    switch (params[0]) {
      case 'dashboard':
        setTitle('Dashboard');
        break;
      case 'stocks':
        setTitle('Stocks');
        break;
      case 'news':
        setTitle('News');
        break;
      case 'return':
        setTitle('Return');
        break;
      case 'history':
        setTitle('History');
        break;
      default:
        setTitle('');
        break;
    }
  }, [params]);
  return title;
};

export default useNavbarTitle;
